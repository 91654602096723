<div class="modal-header">
    <div class="col-md-8 col-xs-8">
        <h4 class="widget-title">Configuración Aplicación</h4>
    </div>
    <div class="col-md-4 col-xs-4">
  <button class="btn btn-primary" type="button" (click)="guardarConfiguracionVista()">
            <i class="fa fa-save align-top bigger-110"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="widget-body">
        <div class="card bcard bgc-transparent shadow-none">
          <div class="card-body tabs-left p-0">
            <ul class="nav nav-tabs align-self-start" role="tablist">
              <li class="nav-item brc-success shadow-sm">
                <a class="link_tab tabla_ventas nav-link text-left py-3" (click)="verTab('tabla_ventas')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Tabla de Ventas
                </a>
              </li>
              <li class="nav-item brc-primary shadow-sm">
                <a class="link_tab registro_venta nav-link text-left py-3" (click)="verTab('registro_venta')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Registro de Venta
                </a>
              </li>
              <li class="nav-item brc-primary shadow-sm">
                <a class="link_tab impresion nav-link text-left py-3" (click)="verTab('impresion')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Impresión
                </a>
              </li>
              <li class="nav-item brc-warning shadow-sm">
                <a class="link_tab permisos nav-link text-left py-3" (click)="verTab('permisos')" data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="home14" aria-selected="true">
                    Permisos
                </a>
              </li>
            </ul>

            <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
              <div class="tab-pane fade show text-95 active" id="home14" role="tabpanel" aria-labelledby="home14-tab-btn">
                <div class="contenido_tab tabla_ventas text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-success-l3 text-success-d4 px-3 py-15 radius-1">
                        <legend >Tabla de Ventas</legend>
                        <div class="row">
                            <div class="col-5">
                                <label>Ordenar por</label>
                            </div>
                            <div class="col-7">
                                <select  class="form-control" name="conf" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.columna">
                                    <option [ngValue]="fieldKey" *ngFor="let fieldKey of configuracion_pagina.getConfKeys()" >{{configuracion_pagina.object.datos.visualizacion_columnas[fieldKey].value}}</option>
                                </select>
                            </div>
                            <div class="col-5">
                                <label>Dirección</label>
                            </div>
                            <div class="col-7">
                                <select class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.direccion">
                                    <option value="asc">Ascendente</option>
                                    <option value="desc">Descendente</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="contenido_tab registro_venta text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
                        <legend >Registro de Venta</legend>
                        <ng-container *ngIf="usuario.empresa.usar_servicios">
                            <div class="row">
                                <div class="col-8">
                                    <label>¿Búsqueda Producto Activado?</label>
                                </div>
                                <div class="col-4">
                                    <label>
                                        <input name="busqueda_codigo_barra" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.busqueda_producto" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                    </label>
                                </div>
                            </div>
                            <hr>
                        </ng-container>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Busqueda Codigo de Barra Activado?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="busqueda_codigo_barra" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.busqueda_codigo_barra" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Cantidad Decimal?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="cantidad_decimal" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.cantidad_decimal" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Modificar Precio Unit.?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="modificar_precio_unitario" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.modificar_precio_unitario" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Modificar Cantidad?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="modificar_cantidad" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.modificar_cantidad" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-6">
                                <label>Tipo de pago por defecto</label>
                            </div>
                            <div class="col-6">
                                <select  class="form-control" name="tipo_pago" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.tipo_pago_por_defecto">
                                <option [disabled]="tipo_pago.nombre=='TODOS'" [ngValue]="tipo_pago.nombre_corto" *ngFor="let tipo_pago of tipos_pago" >{{tipo_pago.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-6">
                                <label>Transacción por defecto</label>
                            </div>
                            <div class="col-6">
                                <select  class="form-control" name="transaccion" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.transaccion_por_defecto">
                                <option [disabled]="transaccion.nombre=='TODOS'" [ngValue]="transaccion.nombre_corto" *ngFor="let transaccion of transacciones" >{{transaccion.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-4">
                                <label>Cantidad por defecto</label>
                            </div>
                            <div class="col-8">
                                <select class="form-control" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.cantidad_por_defecto">
                                    <option value="1">1</option>
                                    <option value="inventario">Inventario</option>
                                </select>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Cantidad habilitado en Búsqueda?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="cantidad_habilitado_busqueda" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.cantidad_habilitado_busqueda" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Visualizar descuento almacen?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="ver_descuento_almacen" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.ver_descuento_almacen" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Visualizar subida de comprobante?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="ver_subida_comprobante_adjunto" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.ver_subida_comprobante_adjunto" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="contenido_tab impresion text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-primary-l3 text-primary-d4 px-3 py-15 radius-1">
                        <legend >Impresión</legend>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Imprimir nota almacen?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="nota_almacen" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.nota_almacen" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                            <div class="col-8">
                                <label>¿Imprimir datos de crédito?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="datos_credito" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.datos_credito" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <label>¿Imprimir al guardar?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="al_guardar" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.al_guardar" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                            <div class="col-8">
                                <label>¿Imprimir usuario?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="impresion_usuario" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.usuario" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                            <div class="col-8">
                                <label>¿Imprimir lineas item?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="lineas_items" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.lineas_items" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                            <div class="col-8">
                                <label>¿Imprimir número pedido?</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <input name="numero_pedido" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.numero_pedido" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                </label>
                            </div>
                            <hr>
                            <div class="col-8">
                                <label>Número de Copias</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <select  class="form-control" name="numero_copias" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.numero_copias">
                                        <option value="1">1 </option>
                                        <option value="2">2 </option>
                                        <option value="3">3 </option>
                                    </select>
                                </label>
                            </div>
                            <div class="col-8">
                                <label>Adición de Copia</label>
                            </div>
                            <div class="col-4">
                                <label>
                                    <select  class="form-control" name="adicion_copia" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.adicion_copia">
                                        <option value="COPIA_IMPRESION_ADICION_HOJA">Adicion de Hoja</option>
                                        <option value="COPIA_IMPRESION_MISMA_HOJA">Misma Hoja</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <ng-container *ngIf="usuario.empresa.usar_facturacion || usuario.empresa.usar_facturacion_computarizada_en_linea">
                            <hr>
                            <div class="row">
                                <div class="col-6">
                                    <label>Tamaño papel factura</label>
                                </div>
                                <div class="col-6">
                                    <select  class="form-control" name="papel_factura" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.papel_factura">
                                    <option [ngValue]="tamano_papel.nombre_corto" *ngFor="let tamano_papel of tamanos_papel" >{{tamano_papel.nombre}}</option>
                                    </select>
                                </div>
                            </div>
                        </ng-container>
                        <hr>
                        <div class="row">
                            <div class="col-6">
                                <label>Tamaño papel nota de venta</label>
                            </div>
                            <div class="col-6">
                                <select  class="form-control" name="papel_nota_venta" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.papel_nota_venta">
                                <option [ngValue]="tamano_papel.nombre_corto" *ngFor="let tamano_papel of tamanos_papel" >{{tamano_papel.nombre}}</option>
                                </select>
                            </div>
                            <div class="col-6">
                                <label>Tamaño papel recibo cobro</label>
                            </div>
                            <div class="col-6">
                                <select  class="form-control" name="papel_recibo_cobro" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.papel_recibo_cobro">
                                <option [ngValue]="tamano_papel.nombre_corto" *ngFor="let tamano_papel of tamanos_papel" >{{tamano_papel.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label>Diseño logo</label>
                            </div>
                            <div class="col-6">
                                <select  class="form-control" name="disenio" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.impresion.disenio_logo_impresion">
                                    <option [ngValue]="disenio.nombre_corto" *ngFor="let disenio of disenios_logo_impresion" >{{disenio.nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="contenido_tab permisos text-primary-d3 text-120 mb-2">
                    <fieldset class="text-110 bgc-orange-l3 text-orange-d4 px-3 py-15 radius-1">
                        <legend >Permisos</legend>
                        <div class="row">
                            <div class="col-6">
                                <label>Canales Asignados</label>
                            </div>
                            <div class="col-6">
                                <ngx-bootstrap-multiselect [options]="opciones_canales" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.canales_asignados" (ngModelChange)="seleccionarCanal($event)"></ngx-bootstrap-multiselect>
                            </div>
                        </div>
                        <ng-container *ngIf="es_usuario_administrador">
                            <hr>
                            <div class="row">
                                <div class="col-8">
                                    <label>¿Ver Excel?</label>
                                </div>
                                <div class="col-4">
                                    <label>
                                        <input name="excel" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.ver_excel" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                    </label>
                                </div>
                                <div class="col-8">
                                    <label>¿Ver Pdf?</label>
                                </div>
                                <div class="col-4">
                                    <label>
                                        <input name="pdf" [(ngModel)]="configuracion_pagina.object.datos.opciones_permisos.ver_pdf" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
                                    </label>
                                </div>
                            </div>
                        </ng-container>
                    </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /.card -->
      </div>
</div>