import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { VentasService } from 'src/app/base/services/ventas/ventas.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { CursosService } from '../../services/cursos/cursos.service';
import { EstudiantesService } from '../../services/estudiantes/estudiantes.service';
import { ReporteCursoService } from '../../services/reporte-curso/reporte-curso.service';
import { CalificacionesService } from '../../services/calificaciones/calificaciones.service';
import { FichaEconomicaEstudianteComponent } from '../ficha-economica-estudiante/ficha-economica-estudiante.component';
import { RegistroEstudianteComponent } from '../registro-estudiante/registro-estudiante.component';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { OrderPipe } from 'ngx-order-pipe';
import { Util } from 'src/app/utils/utils';
import { DocumentosEstudianteComponent } from '../documentos-estudiante/documentos-estudiante.component';
import { RegistroAsuntoAdministrativoComponent } from 'src/app/base/components/registro-asunto-administrativo/registro-asunto-administrativo.component';
import { ConceptosIngresosService } from 'src/app/institute/services/conceptos-ingresos/conceptos-ingresos.service';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { VistaEstudianteComponent } from '../vista-estudiante/vista-estudiante.component';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-reporte-curso',
  templateUrl: './reporte-curso.component.html',
  styleUrls: ['./reporte-curso.component.css']
})
export class ReporteCursoComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  estudiantes:any[]=[];
  edicion_estudiante:NgbModalRef;
  estudiante:any;
  niveles_escolares:any[]=[];
  inscripciones_masivas:any[]=[];
  ficha_economica_modal:NgbModalRef;
  tipo_concepto_pago:any;
  datos_materias_reporte:any[]=[];
  conceptos_pagos:any[]=[];
  materias_reporte:IMultiSelectOption[]=[];
  aplicacion_estudiantes:any;
  documentos_estudiante_materia_modal:NgbModalRef;
  opciones_reporte_promedios=[{nombre:"PROMEDIOS",id:0},{nombre:"ALFABETO",id:1}];
  importacion_pagos_estudiantes:any={estudiantes:[]};
  filtro_masivo:any={};

  importacion_pagos_estudiantes_modal:NgbModalRef;
  @ViewChild('importacion_pagos_estudiantes_modal')
  private importacion_pagos_estudiantes_modal_ref: TemplateRef<any>;

  inscripcion_masiva_modal:NgbModalRef;
  @ViewChild('inscripcion_masiva_modal')
  private inscripcion_masiva_modal_ref: TemplateRef<any>;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService,
    private toastr: ToastrService,
    public ventasService:VentasService,
    private orderPipe: OrderPipe,
    public pdfService:PdfService,
    public cursosService:CursosService,
    public reporteCursoService:ReporteCursoService,
    public estudiantesService:EstudiantesService,
    public calificacionesService:CalificacionesService,
    public conceptosIngresoService:ConceptosIngresosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.filter={
			escuela:null,
      conceptos:[],
      materias:[],
      niveles:[GlobalVariable.Dictionary.SELECCION_TODOS],
      cursos:[GlobalVariable.Dictionary.SELECCION_TODOS],
      tipo_intervalo:{clases:[]},
      opcion_reporte_promedios:{nombre:"PROMEDIOS",id:0},
      sucursales:[GlobalVariable.Dictionary.SELECCION_TODOS].concat(Util.obtenerSucursalesUsuario(this.usuario)),
			sucursal: GlobalVariable.Dictionary.SELECCION_TODOS,
      estados:[GlobalVariable.Dictionary.SELECCION_TODOS,{id:1,nombre:"ACTIVOS"},{id:2,nombre:"ANULADOS"}],
			estado: {id:1,nombre:"ACTIVOS"},
		}
    await this.obtenerEscuela();  
    this.obtenerGestiones();
    this.obtenerNivelesEscolares();
    this.aplicacion_estudiantes= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_ESTUDIANTES)[0];

    let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_sucursales){
			if(!es_usuario_administrador){	
				this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
				this.filter.sucursal=this.filter.sucursales[0];
			}
		}
  }

  obtenerGestiones(){
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).subscribe((entidad:any)=>{
      this.filter.gestiones=entidad.clases
    });
  }

  async obtenerNivelesEscolares(){
    this.filter.niveles=[GlobalVariable.Dictionary.SELECCION_TODOS];
    let niveles_escolares:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.SCH.TIPO_NIVEL_ESCOLAR).toPromise();
    this.niveles_escolares=niveles_escolares.clases;
    this.filter.niveles=this.filter.niveles.concat(niveles_escolares.clases);
  }

  async obtenerCursosPorNivel(id_nivel_escolar){
    this.filter.cursos=[GlobalVariable.Dictionary.SELECCION_TODOS];
    let cursos:any=await this.cursosService.obtenerCursosNivel(this.usuario.id_empresa,id_nivel_escolar).toPromise();
    this.filter.cursos=this.filter.cursos.concat(cursos);
    this.filter.curso=null;
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  async buscarCurso(){
    this.blockUI.start();
    this.datos_materias_reporte=null;
    this.reporteCursoService.obtenerListaEstudiantes(this).subscribe((estudiantes:any[])=>{
      this.estudiantes=estudiantes;
      this.blockUI.stop();
    });
    if(this.usuario.empresa.usar_ingresos_economicos_colegio){
      await this.obtenerConceptosPagoEscuela();
      await this.llenarConceptosPagos(this.tipo_concepto_pago);
    }

    await this.obtenerMateriasReporteCalificaciones();
    await this.llenarMateriasReporte(this.datos_materias_reporte);
    await this.obtenerIntervalosColegio();
  }

  async obtenerIntervalosColegio(){
    this.blockUI.start();
    let tipo_intervalo:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_INTERVALOS_ESCOLAR).toPromise();
    this.filter.tipo_intervalo=tipo_intervalo?tipo_intervalo:{};
    this.filter.intervalo_escolar=this.filter.tipo_intervalo.clases[this.filter.tipo_intervalo.clases.length-1];
    this.blockUI.stop();
  }

  async obtenerConceptosPagoEscuela(){
    this.blockUI.start();
		this.tipo_concepto_pago=await this.conceptosIngresoService.obtenerConceptosPagoColegio(this.filter.gestion).toPromise();
    this.blockUI.stop();
	}

  async obtenerMateriasReporteCalificaciones(){
		let mat_rep:any=await this.reporteCursoService.obtenerMateriasReporteCalificaciones(this).toPromise();
    this.datos_materias_reporte=mat_rep;
	}

  llenarConceptosPagos(conceptos_pagos){
		this.conceptos_pagos=[];
		for(var i=0;i<conceptos_pagos.length;i++){
      let concepto_pago=conceptos_pagos[i];
      concepto_pago.name=conceptos_pagos[i].concepto.nombre;
      concepto_pago.maker="";
      concepto_pago.ticked=false;
			this.conceptos_pagos.push(concepto_pago);
		}
  }

  llenarMateriasReporte(datos_materias_reporte){
		this.materias_reporte=[];
    this.filter.materias=[];
		for(var i=0;i<datos_materias_reporte.length;i++){
			var materia_reporte={
				name:datos_materias_reporte[i].nombre,
				maker: "",
				ticked:true,
				id:datos_materias_reporte[i].id,
        orden:datos_materias_reporte[i].orden,
        area:datos_materias_reporte[i].area
			}
      this.filter.materias.push(datos_materias_reporte[i].id);
			this.materias_reporte.push(materia_reporte);
		}
  }

  modificarEstudiante(estudiante){
    this.blockUI.start();
    this.estudiantesService.obtenerEstudiante(estudiante.id).subscribe((res:any)=>{
      this.blockUI.stop();
      this.estudiante=res;
		  this.abrirRegistroEstudiante();
    });
  }

  abrirRegistroEstudiante(){
		this.edicion_estudiante = this.modalService.open(RegistroEstudianteComponent, {scrollable:true,windowClass : "wizard_edicion_estudiante",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.edicion_estudiante.componentInstance.estudiante = this.estudiante;
    this.edicion_estudiante.componentInstance.usuario = this.usuario;
		this.edicion_estudiante.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.buscarCurso();
			}
			this.edicion_estudiante.close();
    });
  }

  async imprimirRude(estudiante){
    this.blockUI.start();
    this.estudiantesService.obtenerRudesEstudiantes({id_curso:this.filter.curso.id,id_gestion:this.filter.gestion.id,id_estudiante:estudiante.id}).subscribe(async (rudes:any)=>{
      this.pdfService.imprimirRudes(rudes,{codigo_sie:this.filter.escuela.codigo_sie});
      this.blockUI.stop();
    });
  }

  abrirFichaEconomica(estudiante){
    this.ficha_economica_modal = this.modalService.open(FichaEconomicaEstudianteComponent, {scrollable:true, windowClass:'ficha-economica', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.ficha_economica_modal.componentInstance.estudiante = estudiante;
    this.ficha_economica_modal.componentInstance.usuario = this.usuario;
    this.ficha_economica_modal.componentInstance.es_visualizacion_por_gestion = true;
    let inscripcion_colegio={gestion:this.filter.gestion,id_gestion:this.filter.gestion.id,curso:this.filter.curso,id_estudiante:estudiante.id,activo:true};
    if(this.usuario.empresa.usar_acceso_gestion_escolar){
      if(this.usuario.datos && this.usuario.datos.gestiones){
        this.ficha_economica_modal.componentInstance.inscripcion_colegio=this.usuario.datos.gestiones.filter(id_gt => id_gt==this.filter.gestion.id).length>0?inscripcion_colegio:null;
      }      
    }else{
      this.ficha_economica_modal.componentInstance.inscripcion_colegio = inscripcion_colegio;
    }
		
		this.ficha_economica_modal.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.getItems();
			}
			this.ficha_economica_modal.close();
    });
  }

  descargarExcelEstudiantesConceptosPago(){
		this.blockUI.start();
		
    let nombre_archivo='REPORTE CONCEPTOS DE PAGO '+this.filter.gestion.nombre+'-'+this.filter.curso.nombre+'.xlsx';
		let data = [["","REPORTE CONCEPTOS DE PAGO"],
					["",""],
					["","GESTIÓN:"+this.filter.gestion.nombre],
					["","CURSO:"+this.filter.curso.nombre],
					["",""]/*,
					["",""]*/];
    let cabecera=[],totalColumns=[],columsWidth=[];
		cabecera.push("Nº");			
		cabecera.push("CÓDIGO");	
    cabecera.push("APELLIDOS Y NOMBRES");	
    cabecera.push("¿CONSOLIDADO?");	
    cabecera.push("ULTIMA ACTUALIZACION");	
    cabecera.push("CONCEPTOS DE PAGO");
    data.push(cabecera);
		for(let i=0;i<this.estudiantes.length;i++){
      let datos=[];
			datos.push(i+1);
      datos.push(this.estudiantes[i].codigo);
      datos.push(this.estudiantes[i].persona.nombre_completo);
      datos.push(this.estudiantes[i].inscripciones_colegio[0].consolidado?"SÍ":"NO");
      let fecha=new Date(this.estudiantes[i].updatedAt);
      datos.push((fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear()));
      for(let j=0;j<this.estudiantes[i].asignaciones_conceptos_pago.length;j++){
        datos.push(this.estudiantes[i].asignaciones_conceptos_pago[j].concepto_pago.concepto.nombre);
      }
      data.push(datos);
		}

		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("REPORTE");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol1 = worksheet.getColumn(1);
		dobCol1.width = 5;
    const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 10;
		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 50;
    const dobCol4 = worksheet.getColumn(4);
		dobCol4.width = 10;
    

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		  fs.saveAs(blob, nombre_archivo);
		})
		this.blockUI.stop();
	}

  descargarExcelDatosEstudiantes(){
		this.blockUI.start();
		
    let nombre_archivo='REPORTE DATOS DE ESTUDIANTE '+this.filter.gestion.nombre+'-'+this.filter.curso.nombre+'.xlsx';
		let data = [["","REPORTE DATOS DE ESTUDIANTE"],
					["",""],
					["","GESTIÓN:"+this.filter.gestion.nombre],
          ["","NIVEL:"+this.filter.nivel.nombre],
					["","CURSO:"+this.filter.curso.nombre]];
    let cabecera=[],totalColumns=[],columsWidth=[];
		cabecera.push("Nº");			
		cabecera.push("CÓDIGO");	
    cabecera.push("CÓDIGO RUDE");	
    cabecera.push("APELLIDO PATERNO");	
    cabecera.push("APELLIDOS MATERNO");	
    cabecera.push("NOMBRES");	
    cabecera.push("C.I.");	
    cabecera.push("GÉNERO");
    cabecera.push("FECHA DE NACIMIENTO");
    cabecera.push("LUGAR DE NACIMIENTO");
    cabecera.push("DIRECCIÓN DOMICILIO");
    cabecera.push("TELÉFONO-CELULAR");
    cabecera.push("CORREO ELECTRÓNICO");
    cabecera.push("DATOS TUTOR 1");
    cabecera.push("PARENTESCO");
    cabecera.push("LUGAR DE TRABAJO");
    cabecera.push("TELÉFONO-CELULAR");
    cabecera.push("CORREO ELECTRÓNICO");
    cabecera.push("DATOS TUTOR 2");
    cabecera.push("PARENTESCO");
    cabecera.push("LUGAR DE TRABAJO");
    cabecera.push("TELÉFONO-CELULAR");
    cabecera.push("CORREO ELECTRÓNICO");
    cabecera.push("DATOS TUTOR 3");
    cabecera.push("PARENTESCO");
    cabecera.push("LUGAR DE TRABAJO");
    cabecera.push("TELÉFONO-CELULAR");
    cabecera.push("CORREO ELECTRÓNICO");
    data.push(cabecera);
		for(let i=0;i<this.estudiantes.length;i++){
      let datos=[];
			datos.push(i+1);
      datos.push(this.estudiantes[i].codigo);
      datos.push(this.estudiantes[i].codigo_rude);
      datos.push(this.estudiantes[i].persona.apellido_paterno);
      datos.push(this.estudiantes[i].persona.apellido_materno);
      datos.push(this.estudiantes[i].persona.nombres);
      datos.push(this.estudiantes[i].persona.ci);
      datos.push(this.estudiantes[i].persona.genero.nombre);
      let fecha=new Date(this.estudiantes[i].persona.fecha_nacimiento);
      datos.push((fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear()));
      datos.push(this.estudiantes[i].persona.provincia_nacimiento);
      datos.push(this.estudiantes[i].persona.direccion_zona+"-"+this.estudiantes[i].persona.direccion+"-"+this.estudiantes[i].persona.direccion_numero);
      datos.push(this.estudiantes[i].persona.telefono+"-"+this.estudiantes[i].persona.telefono_movil);
      datos.push(this.estudiantes[i].persona.correo_electronico);
      for(let j=0;j<this.estudiantes[i].tutores.length;j++){
        datos.push(this.estudiantes[i].tutores[j].persona.nombre_completo);
        datos.push(this.estudiantes[i].tutores[j].parentesco?this.estudiantes[i].tutores[j].parentesco.nombre:"");
        datos.push(this.estudiantes[i].tutores[j].lugar_trabajo);
        datos.push(this.estudiantes[i].tutores[j].persona.telefono+"-"+this.estudiantes[i].tutores[j].persona.telefono_movil);
        datos.push(this.estudiantes[i].tutores[j].persona.correo_electronico);
      }
      data.push(datos);
		}

		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("REPORTE");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol1 = worksheet.getColumn(1);
		dobCol1.width = 5;
    const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 10;
    const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 20;
		const dobCol4 = worksheet.getColumn(4);
		dobCol4.width = 20;
    const dobCol5 = worksheet.getColumn(5);
		dobCol5.width = 20;
    const dobCol6 = worksheet.getColumn(6);
		dobCol6.width = 20;
    const dobCol14 = worksheet.getColumn(14);
		dobCol14.width = 50;
    const dobCol19 = worksheet.getColumn(19);
		dobCol19.width = 50;
    const dobCol24 = worksheet.getColumn(24);
		dobCol24.width = 50;
    

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		  fs.saveAs(blob, nombre_archivo);
		})
		this.blockUI.stop();
	}

  generarReporteExcelPagos(){
		this.blockUI.start();
		this.reporteCursoService.obtenerReportePagosEstudiantes(this).subscribe((datos:any)=>{
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("REPORTE PAGOS");
      worksheet.addRow(["","REPORTE DE PAGOS"]);
      worksheet.addRow(["",""]);
      worksheet.addRow(["","GESTIÓN:"+this.filter.gestion.nombre]);
      worksheet.addRow(["","UNIDAD EDUCATIVA:"+this.filter.escuela.nombre]);
      worksheet.addRow(["","CURSO:"+this.filter.curso.nombre]);
      worksheet.addRow(["",""]);
      let students_payments=datos.estudiantes;
			let student_payment_concepts=datos.conceptos_pagos;

			let nombre_archivo='REPORTE PAGOS '+this.filter.gestion.nombre+'-'+this.filter.escuela.nombre+'-'+this.filter.nivel.nombre+'-'+this.filter.curso.nombre+'.xlsx';
			var headerColumns=[],paymentColumns=[],totalColumns=[],columsWidth=[];
			headerColumns.push("Nº");			
      headerColumns.push("CÓDIGO");			
			headerColumns.push("APELLIDOS Y NOMBRES");	
			paymentColumns.push("");
			paymentColumns.push("");
      paymentColumns.push("");
			totalColumns.push("");
      totalColumns.push("");
			totalColumns.push("Total x cuotas");
      student_payment_concepts=this.orderPipe.transform(student_payment_concepts, 'concept.id',false);
			//student_payment_concepts=$filter('orderBy')(student_payment_concepts,'concept.id',false);
			for(var i=0;i<student_payment_concepts.length;i++){
        let nombre_concepto=(student_payment_concepts[i].concepto.nombre_corto?student_payment_concepts[i].concepto.nombre_corto+"-":"")+student_payment_concepts[i].concepto.nombre;
				paymentColumns.push(nombre_concepto);
				for(var j=1;j<=student_payment_concepts[i].cuotas;j++){
					headerColumns.push(j+" "+student_payment_concepts[i].concepto.nombre);	
					columsWidth.push({wch:4});
					if(j>1){
						paymentColumns.push(null);
					}
					totalColumns.push(0);
				}
			}
			headerColumns.push("Total x estudiantes");	
      worksheet.addRow(paymentColumns);
      worksheet.addRow(headerColumns);
			var total_per_students=0,fechas_pagos=[];
			for(var j=0;j<students_payments.length;j++){
				var columns=[];
				columns.push((j+1));
        columns.push(students_payments[j].codigo);
				columns.push(students_payments[j].persona.nombre_completo);
				//students_payments[j].conceptos_pagos_estudiantes_colegio=$filter('orderBy')(students_payments[j].conceptos_pagos_estudiantes_colegio,);
        students_payments[j].conceptos_pagos_estudiantes_colegio=this.orderPipe.transform(students_payments[j].conceptos_pagos_estudiantes_colegio,['concepto_pago.concepto.id','cuota'],false);
				var i=0,k=3,total_per_student=0;
				while(i<students_payments[j].conceptos_pagos_estudiantes_colegio.length){
					if(students_payments[j].conceptos_pagos_estudiantes_colegio[i].concepto_pago.concepto){
						if(headerColumns[k].indexOf(students_payments[j].conceptos_pagos_estudiantes_colegio[i].cuota+" "+students_payments[j].conceptos_pagos_estudiantes_colegio[i].concepto_pago.concepto.nombre) !== -1){
							let total_price:any=0,fecha_pago:string="";
							if(students_payments[j].conceptos_pagos_estudiantes_colegio[i].detalles_venta.length>0){
								for(let h=0;h<students_payments[j].conceptos_pagos_estudiantes_colegio[i].detalles_venta.length;h++){
									total_price=total_price+students_payments[j].conceptos_pagos_estudiantes_colegio[i].detalles_venta[h].detalle_venta.total;
                  let fecha_pago_dato=new Date(students_payments[j].conceptos_pagos_estudiantes_colegio[i].detalles_venta[h].detalle_venta.venta.fecha);
                  fecha_pago=fecha_pago+" "+(fecha_pago_dato.getDate()+"/"+(fecha_pago_dato.getMonth()+1)+"/"+fecha_pago_dato.getFullYear());
								}
							}else if(students_payments[j].conceptos_pagos_estudiantes_colegio[i].pagado>0){
								total_price=students_payments[j].conceptos_pagos_estudiantes_colegio[i].pagado
							}else{
								total_price="";
							}
							total_per_student=total_per_student+(total_price==""?0:total_price);
              var dato=total_price;
              if(this.configuracion_pagina.getOrden().reporte_pagos_con_fechas){
                dato=dato+" "+fecha_pago;
              }
							columns.push(dato);
              fechas_pagos.push({fecha_pago:fecha_pago,letra:GlobalVariable.Dictionary.LETRAS_EXCEL[k],fila:(j+9)});
							totalColumns[k]=totalColumns[k]+(total_price==""?0:total_price);
							i++;
							k++;
						}else{
							columns.push("");
							//columsWidth.push({wch:4});
							k++;
						}
					}else{
						i++;
						k++;
					}
				}

				total_per_students=total_per_students+total_per_student;

				for (var i=columns.length;i<headerColumns.length-1;i++){
					columns.push("");
				}

				columns.push(total_per_student);				
        let row =worksheet.addRow(columns);
        row.height = this.configuracion_pagina.getOrden().reporte_pagos_con_fechas?30:20;
			}

      if(this.configuracion_pagina.getOrden().reporte_pagos_con_fechas){
        for(var j=0;j<fechas_pagos.length;j++){
          worksheet.getCell(fechas_pagos[j].letra+""+fechas_pagos[j].fila).dataValidation  = { 
            type: 'whole',operator: 'between',
            allowBlank: false,
            showInputMessage: true,
            showErrorMessage: true,
            errorStyle: 'alert',
            errorTitle: 'Error de Validación',
            error: '¡No puede modificar la celda!',
            formulae: [1, 40],
            promptTitle: 'Fecha de Pago',
            prompt: ''+fechas_pagos[j].fecha_pago +''
          };
          worksheet.getCell(fechas_pagos[j].letra+""+fechas_pagos[j].fila).alignment = { wrapText: true };
        }
      }

			var total_payments=0;
			for (var i=3;i<totalColumns.length;i++){
				total_payments=total_payments+totalColumns[i];
			}
			totalColumns.push("");
      totalColumns.push("");
			columsWidth.push({wch:4});
			totalColumns.push(total_payments);
			columsWidth.push({wch:5});
			totalColumns.push(total_per_students);
			columsWidth.push({wch:5});
      worksheet.addRow(totalColumns);


      const dobCol1 = worksheet.getColumn(1);
      dobCol1.width = 5;
      const dobCol2 = worksheet.getColumn(2);
      dobCol2.width = 10;
      const dobCol3 = worksheet.getColumn(3);
      dobCol3.width = 45;
      for(let i=4;i<headerColumns.length+1;i++){
        let dobCol = worksheet.getColumn(i);
        dobCol.width = this.configuracion_pagina.getOrden().reporte_pagos_con_fechas?11:6;
      }

      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, nombre_archivo);
      })
      this.blockUI.stop();
    });
	}

  generarReporteExcelPagosMes(){
		this.blockUI.start();
		this.reporteCursoService.obtenerReportePagosEstudiantes(this).subscribe((datos:any)=>{
      this.generalService.obtenerClases("MESES").subscribe((monthType:any)=>{
        let months=monthType.clases;
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("REPORTE PAGOS");
        worksheet.addRow(["","REPORTE DE PAGOS"]);
        worksheet.addRow(["",""]);
        worksheet.addRow(["","GESTIÓN:"+this.filter.gestion.nombre]);
        worksheet.addRow(["","UNIDAD EDUCATIVA:"+this.filter.escuela.nombre]);
        worksheet.addRow(["","CURSO:"+this.filter.curso.nombre]);
        worksheet.addRow(["",""]);
        let students_payments=datos.estudiantes;
        let student_payment_concepts=datos.conceptos_pagos;

        let nombre_archivo='REPORTE PAGOS '+this.filter.gestion.nombre+'-'+this.filter.escuela.nombre+'-'+this.filter.nivel.nombre+'-'+this.filter.curso.nombre+'.xlsx';
        var headerColumns=[],paymentColumns=[],totalColumns=[],columsWidth=[];
        headerColumns.push("Nº");			
        headerColumns.push("CÓDIGO");			
        headerColumns.push("APELLIDOS Y NOMBRES");	
        paymentColumns.push("");
        paymentColumns.push("");
        paymentColumns.push("");
        totalColumns.push("");
        totalColumns.push("");
        totalColumns.push("Total x meses");

				student_payment_concepts=this.orderPipe.transform(student_payment_concepts,['concepto_pago.concepto.id'],false);
				for(var i=0;i<student_payment_concepts.length;i++){
					paymentColumns.push(student_payment_concepts[i].concepto.nombre);
					for(var j=0;j<months.length;j++){
						headerColumns.push(months[j].nombre);	
						columsWidth.push({wch:10});
						if(j>0){
							paymentColumns.push(null);
						}
						totalColumns.push(0);
					}
				}
				headerColumns.push("Total x estudiantes");	
				worksheet.addRow(paymentColumns);
				worksheet.addRow(headerColumns);
				var total_per_students=0;
				var total_per_months=0;
				for(var j=0;j<students_payments.length;j++){
					var columns=[];
					columns.push(j+1);
          columns.push(students_payments[j].codigo);
					columns.push(students_payments[j].persona.nombre_completo);
					var student_payment_months=this.filtrarPagosEstudiantePorMes(students_payments[j].conceptos_pagos_estudiantes_colegio,student_payment_concepts,months);

					var i=0,k=3,total_per_student=0;;
					while(i<student_payment_months.length){
						for(var h=0;h<student_payment_months[i].payments_month.length;h++){
							total_per_student=total_per_student+student_payment_months[i].payments_month[h];
							columns.push(student_payment_months[i].payments_month[h]);
							totalColumns[k]=totalColumns[k]+student_payment_months[i].payments_month[h];
							k++;
						}
						i++;
					}

					total_per_students=total_per_students+total_per_student;

					for (var i=columns.length;i<headerColumns.length-1;i++){
						columns.push("");
					}

					columns.push(total_per_student);
					worksheet.addRow(columns);
				}

				var total_payments=0;
				for (var i=3;i<totalColumns.length;i++){
					total_payments=total_payments+totalColumns[i];
				}
        totalColumns.push("");
        totalColumns.push("");
        columsWidth.push({wch:4});
        totalColumns.push(total_payments);
				columsWidth.push({wch:5});
				totalColumns.push(total_per_students);
				columsWidth.push({wch:5});
				worksheet.addRow(totalColumns);
				
				const dobCol1 = worksheet.getColumn(1);
        dobCol1.width = 5;
        const dobCol2 = worksheet.getColumn(2);
        dobCol2.width = 10;
        const dobCol3 = worksheet.getColumn(3);
        dobCol3.width = 45;

        workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, nombre_archivo);
        })
        this.blockUI.stop();
			});
		});
	}

  filtrarPagosEstudiantePorMes(student_payments,student_payment_concepts,months){
		for(var i=0;i<student_payment_concepts.length;i++){
			var student_payments_by_concept=student_payments.filter( sp => sp.id_concepto_pago==student_payment_concepts[i].id);
			var payment_dates=[];
			var payments_month=[0,0,0,0,0,0,0,0,0,0,0,0];
			for(let j=0;j<student_payments_by_concept.length;j++){
				if(student_payments_by_concept[j].detalles_venta.length>0){
					for(let h=0;h<student_payments_by_concept[j].detalles_venta.length;h++){
						payment_dates.push({total:student_payments_by_concept[j].detalles_venta[h].detalle_venta.total,fecha:student_payments_by_concept[j].detalles_venta[h].detalle_venta.venta.fecha});
					}
				}
			}
      payment_dates=this.orderPipe.transform(payment_dates, 'fecha',false);
			for(var k=0;k<payment_dates.length;k++){
				var date=new Date(payment_dates[k].fecha);
				payments_month[date.getMonth()]=payments_month[date.getMonth()]+payment_dates[k].total;
			}
			student_payment_concepts[i].payments_month=payments_month;
		}
		return student_payment_concepts;
	}

  async generarBoletinCalificaciones(intervalo_escolar_hasta,ids_materias_seleccionadas,ids_estudiantes){
      this.blockUI.start();
      var estudiantes_intervalos=[];
      var primer_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="1")[0];
      primer_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerBoletinCalificaciones(this.usuario.id_empresa,this.filter.gestion.id,this.filter.curso.id,primer_intervalo_escolar.id,ids_materias_seleccionadas,ids_estudiantes).toPromise();
      for(var i=0;i<primer_intervalo_escolar.estudiantes.length;i++){
        for(var j=0;j<primer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
          primer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].materia=this.datos_materias_reporte.filter( m => m.id==primer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].id_materia)[0];
        }
      }
      estudiantes_intervalos.push(primer_intervalo_escolar);
      var segundo_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="2")[0];
      segundo_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerBoletinCalificaciones(this.usuario.id_empresa,this.filter.gestion.id,this.filter.curso.id,segundo_intervalo_escolar.id,ids_materias_seleccionadas,ids_estudiantes).toPromise();
      for(var i=0;i<segundo_intervalo_escolar.estudiantes.length;i++){
        for(var j=0;j<segundo_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
          segundo_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].materia=this.datos_materias_reporte.filter( m => m.id==segundo_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].id_materia)[0];
        }
      }
      estudiantes_intervalos.push(segundo_intervalo_escolar);
      var tercer_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="3")[0];
      tercer_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerBoletinCalificaciones(this.usuario.id_empresa,this.filter.gestion.id,this.filter.curso.id,tercer_intervalo_escolar.id,ids_materias_seleccionadas,ids_estudiantes).toPromise();
      for(var i=0;i<tercer_intervalo_escolar.estudiantes.length;i++){
        for(var j=0;j<tercer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
          tercer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].materia=this.datos_materias_reporte.filter( m => m.id==tercer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].id_materia)[0];
        }
      }
      estudiantes_intervalos.push(tercer_intervalo_escolar);
      await this.generarPdfBoletinCalificaciones(estudiantes_intervalos,primer_intervalo_escolar,intervalo_escolar_hasta,false);
      this.blockUI.stop();
  }

  async obtenerCantidadTotalCasillas(dimensiones){
    dimensiones=this.orderPipe.transform(dimensiones, 'orden',false);
    let cantidad_total_casillas:number=1;
    for(let i=0;i<dimensiones.length;i++){
      cantidad_total_casillas=cantidad_total_casillas+parseInt(dimensiones[i].numero_casillas.toString())+2;
    }
    return cantidad_total_casillas;
  }

  async generarPdfBoletinCalificaciones(estudiantes_intervalos,primer_intervalo_escolar,intervalo_escolar_hasta,mostrar_dimensiones){
    let configuraciones_calificacion_colegio:any=await this.calificacionesService.obtenerConfiguracionCalificacion(this.filter.gestion.id,this.usuario.id_empresa).toPromise();
		let nombre_documento='BOLETIN-NOTAS-'+this.filter.gestion.nombre+'-'+this.filter.escuela.nombre+'-'+this.filter.curso.nombre+'.pdf';
    let docScoreCard = new PDFDocument({ size:[612,396],margin: 0 });
		let stream = docScoreCard.pipe(blobStream());
					
    for(var studentIndex=0;studentIndex<primer_intervalo_escolar.estudiantes.length;studentIndex++){
      var numberOfCards=1;//(printTracking?1:2);
      var yPage=0;
      for(var item=0;item<numberOfCards && studentIndex<primer_intervalo_escolar.estudiantes.length;item++){
        var averages=[];
        docScoreCard.font('Helvetica-Bold',13);
        docScoreCard.text("BOLETÍN DE CALIFICACIONES",200,yPage+20);
        docScoreCard.fontSize(9);
        docScoreCard.text(this.filter.nivel.nombre,170,yPage+40);
        docScoreCard.fontSize(8);
        docScoreCard.image(this.usuario.empresa.imagen,500, yPage+15, {width:60, height:60});
        docScoreCard.text("UNIDAD EDUCATIVA : "+this.filter.escuela.nombre,50,yPage+60);
        //docScoreCard.text("DEPENDENCIA : "+this.filter.escuela.dependencia.nombre,50,yPage+70);
        docScoreCard.text("TURNO : "+this.filter.escuela.turno.nombre,50,yPage+80);
        
        docScoreCard.text("GESTIÓN : "+this.filter.gestion.nombre,200,yPage+70);
        docScoreCard.text("AÑO DE ESCOLARIDAD : "+this.filter.curso.nombre,200,yPage+80);
        
        //docScoreCard.text('CÓDIGO RUDE : '+primer_intervalo_escolar.estudiantes[studentIndex].codigo_rude,40,yPage+100);
        docScoreCard.text('APELLIDOS Y NOMBRES : '+primer_intervalo_escolar.estudiantes[studentIndex].persona.nombre_completo,200,yPage+100);
        docScoreCard.rect(30,yPage+95,545,20).stroke();
        docScoreCard.text('Nº',33,yPage+125);
        docScoreCard.rect(30,yPage+115,40,30).stroke();
        docScoreCard.text('ÁREA',46,yPage+125);
        docScoreCard.rect(45,yPage+115,25,30).stroke();
        docScoreCard.text('MATERIA',120,yPage+125);
        docScoreCard.rect(70,yPage+115,190,30).stroke();
        docScoreCard.text('VALORACIÓN CUANTITATIVA',340,yPage+118);
        docScoreCard.rect(260,yPage+115,315,11).stroke();
        docScoreCard.text('TRIMESTRE',370,yPage+129);
        docScoreCard.rect(260,yPage+126,252,10).stroke();

        
        //sortSubjects(studentsScores[studentIndex].subjectsScores);
        var x=260,xRect=260,incremento=mostrar_dimensiones?23:63;
        for(var b=0;b<estudiantes_intervalos.length;b++){
          if(mostrar_dimensiones){
            if(intervalo_escolar_hasta.nombre_corto==estudiantes_intervalos[b].nombre_corto){
              docScoreCard.fontSize(5);
              /*let cantidad_total_casillas=await this.obtenerCantidadTotalCasillas(dimensiones);
              let finalScores=primer_intervalo_escolar.estudiantes[0].inscripciones_colegio[0].calificaciones.filter( c => c.orden==cantidad_total_casillas );
              Util.ordernarMaterias(finalScores);*/

              for(var bD=0;bD<configuraciones_calificacion_colegio.dimensiones.length;bD++){
                docScoreCard.text(configuraciones_calificacion_colegio.dimensiones[bD].dimension.nombre,x+1,yPage+138);
                docScoreCard.rect(xRect,yPage+136,incremento,9).stroke();
                x=x+incremento;
                xRect=xRect+incremento;
              }
              docScoreCard.fontSize(8);
            }
            docScoreCard.text(estudiantes_intervalos[b].nombre_corto+"º",x+3,yPage+138);
            docScoreCard.text("P.A.",x+incremento,yPage+138);
            docScoreCard.rect(xRect,yPage+136,(incremento*2),9).stroke();
            x=x+(incremento*2);
            xRect=xRect+(incremento*2);
          }else{
            docScoreCard.text(estudiantes_intervalos[b].nombre_corto+"º",x+10,yPage+138);
            docScoreCard.text("P.A.",x+40,yPage+138);
            docScoreCard.rect(xRect,yPage+136,incremento,9).stroke();
            x=x+incremento;
            xRect=xRect+incremento;
          }
        }
        docScoreCard.text("PROM.",xRect+(mostrar_dimensiones?3:10),yPage+129);
        docScoreCard.text("ANUAL",xRect+(mostrar_dimensiones?3:10),yPage+138);
        docScoreCard.rect(xRect,yPage+126,(mostrar_dimensiones?39:incremento),19).stroke();
        docScoreCard.font('Helvetica');
        var y=152,showAnualAverage=true;

        let calificaciones_estudiantes_materias = (estudiantes_intervalos.length>0 && estudiantes_intervalos[0].estudiantes.length>0)?estudiantes_intervalos[0].estudiantes:[];
        let calificaciones_materias = calificaciones_estudiantes_materias.length>0?calificaciones_estudiantes_materias[Util.obtenerEstudianteMayorCantidadCalificaciones(calificaciones_estudiantes_materias)].inscripciones_colegio[0].calificaciones.filter((calificacion, i, arr) => arr.findIndex(t => t.id_materia === calificacion.id_materia) === i):[];
        //let calificaciones_materias = (estudiantes_intervalos.length>0 && estudiantes_intervalos[0].estudiantes.length>0)?estudiantes_intervalos[0].estudiantes[0].inscripciones_colegio[0].calificaciones.filter((calificacion, i, arr) => arr.findIndex(t => t.id_materia === calificacion.id_materia) === i):[];
        Util.ordernarMaterias(calificaciones_materias);
        for(var j=0;j<this.materias_reporte.length;j++){
          docScoreCard.fillColor('black');
          docScoreCard.text((j+1),33,yPage+y-2);
          docScoreCard.rect(30,yPage+y-7,15,15).stroke();
          docScoreCard.text(this.materias_reporte[j].name,72,yPage+y-2);
          docScoreCard.rect(70,yPage+y-7,190,15).stroke();
          docScoreCard.rect(xRect,yPage+y-7,(mostrar_dimensiones?39:incremento),15).stroke();
          y=y+15;
        }

        y=152,x=260,xRect=260;
        for(let j=0;j<estudiantes_intervalos.length;j++){
          let sum=0;
          y=152;
          let average=[];
          let areaList=[];
          /*if(mostrar_dimensiones && intervalo_escolar_hasta.nombre_corto==estudiantes_intervalos[j].nombre_corto){
            let firstDimensionStudentScores=estudiantes_intervalos[j].estudiantes[studentIndex].inscripciones_colegio[0].calificaciones.filter( c => c.orden==configuraciones_calificacion[0].numero_casillas+3 );
            Util.ordernarMaterias(firstDimensionStudentScores);
            let secondDimensionStudentScores=estudiantes_intervalos[j].estudiantes[studentIndex].inscripciones_colegio[0].calificaciones.filter(c => c.orden==configuraciones_calificacion[0].numero_casillas+configuraciones_calificacion[1].numero_casillas+6);
            Util.ordernarMaterias(secondDimensionStudentScores);
            let thirdDimensionStudentScores=estudiantes_intervalos[j].estudiantes[studentIndex].inscripciones_colegio[0].calificaciones.filter(c => c.orden==configuraciones_calificacion[0].numero_casillas+configuraciones_calificacion[1].numero_casillas+configuraciones_calificacion[2].numero_casillas+9);
            Util.ordernarMaterias(thirdDimensionStudentScores);
            let fourthDimensionStudentScores=estudiantes_intervalos[j].estudiantes[studentIndex].inscripciones_colegio[0].calificaciones.filter(c => c.orden==configuraciones_calificacion[0].numero_casillas+configuraciones_calificacion[1].numero_casillas+configuraciones_calificacion[2].numero_casillas+configuraciones_calificacion[3].numero_casillas+12);
            Util.ordernarMaterias(fourthDimensionStudentScores);
            for(var bD=0;bD<firstDimensionStudentScores.length;bD++){
              docScoreCard.text((firstDimensionStudentScores[bD].valor?firstDimensionStudentScores[bD].valor:""),x+3,yPage+y-2);
              docScoreCard.rect(xRect,yPage+y-7,incremento,15).stroke();
              y=y+15;
            }
            y=152;
            x=x+incremento;
            xRect=xRect+incremento;
            for(var bD=0;bD<secondDimensionStudentScores.length;bD++){
              docScoreCard.text((secondDimensionStudentScores[bD].valor?secondDimensionStudentScores[bD].valor:""),x+3,yPage+y-2);
              docScoreCard.rect(xRect,yPage+y-7,incremento,15).stroke();
              y=y+15;
            }
            y=152;
            x=x+incremento;
            xRect=xRect+incremento;
            for(var bD=0;bD<thirdDimensionStudentScores.length;bD++){
              docScoreCard.text((thirdDimensionStudentScores[bD].valor?thirdDimensionStudentScores[bD].valor:""),x+3,yPage+y-2);
              docScoreCard.rect(xRect,yPage+y-7,incremento,15).stroke();
              y=y+15;
            }
            y=152;
            x=x+incremento;
            xRect=xRect+incremento;
            for(var bD=0;bD<fourthDimensionStudentScores.length;bD++){
              docScoreCard.text((fourthDimensionStudentScores[bD].valor?fourthDimensionStudentScores[bD].valor:""),x+3,yPage+y-2);
              docScoreCard.rect(xRect,yPage+y-7,incremento,15).stroke();
              y=y+15;
            }
            y=152;
            x=x+incremento;
            xRect=xRect+incremento;
          }*/
          
          if(estudiantes_intervalos[j].estudiantes.length>0){

            let calificaciones_materias = estudiantes_intervalos[j].estudiantes[studentIndex].inscripciones_colegio[0].calificaciones.filter((calificacion, i, arr) => arr.findIndex(t => t.id_materia === calificacion.id_materia) === i);
            calificaciones_materias=Util.completarMaterias(this.materias_reporte,calificaciones_materias);
            let finalStudentScores=[];
            for(let ic=0;ic<calificaciones_materias.length;ic++){
              //let dimensiones=JSON.parse(calificaciones_materias[ic].materia.registros_profesor[0].configuracion).dimensiones;
              //dimensiones=dimensiones?dimensiones:[];
              //let cantidad_total_casillas=await this.obtenerCantidadTotalCasillas(dimensiones);
              let calificaciones_materia=estudiantes_intervalos[j].estudiantes[studentIndex].inscripciones_colegio[0].calificaciones;
              calificaciones_materia=Util.completarMaterias(this.materias_reporte,calificaciones_materia);
              calificaciones_materia=estudiantes_intervalos[j].estudiantes[studentIndex].inscripciones_colegio[0].calificaciones.filter( c=>(c.id_materia==calificaciones_materias[ic].id_materia));
              let calificacion_final_materia = calificaciones_materia.reduce(((prev, current) => (prev.orden > current.orden) ? prev : current),0);
              if(calificacion_final_materia){
                finalStudentScores.push(calificacion_final_materia);
              }
            }
            Util.ordernarMaterias(finalStudentScores);
            for(var i=0;i<finalStudentScores.length;i++){
              //Control sobre el bimestre hasta el cual se debe calcular el promedio
              if(j<parseInt(intervalo_escolar_hasta.nombre_corto)){
                average.push(finalStudentScores[i].valor);
              }

              if(finalStudentScores[i].valor && finalStudentScores[i].valor<51){
                docScoreCard.fillColor('red');
              }else{
                docScoreCard.fillColor('black');
              }
              
              if(finalStudentScores[i].valor>0){
                docScoreCard.text(finalStudentScores[i].valor,x+(mostrar_dimensiones?3:10),yPage+y-2);
                if(finalStudentScores[i].materia.area!=null && !Util.estaEnListaArea(areaList,finalStudentScores[i].materia.area)){
                  var res=Util.calcularPromedioArea(finalStudentScores[i].materia.area,finalStudentScores,areaList);
                  if(res!=0){
                    if(res[0]<51){
                      docScoreCard.fillColor('red');
                    }else{
                      docScoreCard.fillColor('black');
                    }
                    docScoreCard.text(res[0],x+(mostrar_dimensiones?incremento+3:(incremento/2)+10),yPage+y-2+((15*(res[1]-1))/2));
                    docScoreCard.fillColor('black');
                    docScoreCard.rect(xRect+(mostrar_dimensiones?incremento:(incremento/2)),yPage+y-7,(mostrar_dimensiones?incremento:(incremento/2)),15*res[1]).stroke();
                    docScoreCard.text(res[2].nombre_corto,48,yPage+y-2+((15*(res[1]-1))/2));
                    docScoreCard.rect(45,yPage+y-7,25,15*res[1]).stroke();
                  }else{
                    docScoreCard.text(finalStudentScores[i].valor,x+(mostrar_dimensiones?incremento+3:(incremento/2)+10),yPage+y-2);
                    docScoreCard.rect(xRect+(mostrar_dimensiones?incremento:(incremento/2)),yPage+y-7,(mostrar_dimensiones?incremento:(incremento/2)),15).stroke();
                    docScoreCard.text(finalStudentScores[i].materia.area.nombre_corto,48,yPage+y-2);
                    docScoreCard.rect(45,yPage+y-7,25,15).stroke();
                  }
                }else{
                  if(finalStudentScores[i].materia.area==null){
                    docScoreCard.text(finalStudentScores[i].valor,x+(mostrar_dimensiones?incremento+3:(incremento/2)+10),yPage+y-2);
                    docScoreCard.rect(xRect+(mostrar_dimensiones?incremento:(incremento/2)),yPage+y-7,(mostrar_dimensiones?incremento:(incremento/2)),15).stroke();
                    docScoreCard.rect(45,yPage+y-7,25,15).stroke();
                  }
                }

              }else{
                showAnualAverage=false;
                docScoreCard.rect(xRect+(mostrar_dimensiones?incremento:(incremento/2)),yPage+y-7,(mostrar_dimensiones?incremento:(incremento/2)),15).stroke();
                //docScoreCard.rect(45,yPage+y-7,25,15).stroke();
                //docScoreCard.text(finalStudentScores[i].materia.area.nombre_corto,48,yPage+y-2);
              }
              docScoreCard.rect(xRect,yPage+y-7,(mostrar_dimensiones?incremento:(incremento/2)),15).stroke();
              y=y+15;
              docScoreCard.fillColor('black');
            }

          }

          //Control sobre el bimestre hasta el cual se debe calcular el promedio
          if(j<parseInt(intervalo_escolar_hasta.nombre_corto)){
            averages.push(average);
          }
          x=x+(mostrar_dimensiones?incremento*2:incremento);
          xRect=xRect+(mostrar_dimensiones?incremento*2:incremento);
        }

        var yF=152;
        var sum;
        for(var j=0;j<averages[0].length;j++){
          sum=0;
          for(var t=0;t<parseInt(intervalo_escolar_hasta.nombre_corto);t++){
            sum=sum+(averages[t][j]?averages[t][j]:0);
          }
          if(Math.round(sum/parseInt(intervalo_escolar_hasta.nombre_corto))<51){
            docScoreCard.fillColor('red');
          }else{
            docScoreCard.fillColor('black');
          }
          if(showAnualAverage){
            docScoreCard.text(Math.round(sum/parseInt(intervalo_escolar_hasta.nombre_corto)),x+(mostrar_dimensiones?13:25),yPage+yF-2);
          }
          yF=yF+15;
        }	
        
        docScoreCard.fillColor('black');
        //if(!printTracking){
          if(item==0 && numberOfCards==2){
            studentIndex++;
            yPage=yPage+396;
          }else{
            docScoreCard.addPage();
          }
        /*}else{
          if(primer_intervalo_escolar.estudiantes[studentIndex].trackings.length>0){
            $scope.getStudentTrackingPDF(docScoreCard,management,school,grade,primer_intervalo_escolar.estudiantes[studentIndex],y);
          }
          docScoreCard.addPage();
        }*/
      }		
    }

    docScoreCard.end();
    this.blockUI.stop();
    stream.on('finish', function() {
      var fileURL = stream.toBlobURL('application/pdf');
      //Util.descargarDocumento(nombre_documento,fileURL);
      window.open(fileURL,'_blank','location=no');
    });
  }

  async generarBoletinCalificacionesInicial(intervalo_escolar_hasta,ids_materias_seleccionadas,ids_estudiantes){
    this.blockUI.start();
    var estudiantes_intervalos=[];
    var primer_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="1")[0];
    primer_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerBoletinCalificaciones(this.usuario.id_empresa,this.filter.gestion.id,this.filter.curso.id,primer_intervalo_escolar.id,ids_materias_seleccionadas,ids_estudiantes).toPromise();
    for(var i=0;i<primer_intervalo_escolar.estudiantes.length;i++){
      for(var j=0;j<primer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
        primer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].materia=this.datos_materias_reporte.filter( m => m.id==primer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].id_materia)[0];
      }
    }
    estudiantes_intervalos.push(primer_intervalo_escolar);
    var segundo_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="2")[0];
    segundo_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerBoletinCalificaciones(this.usuario.id_empresa,this.filter.gestion.id,this.filter.curso.id,segundo_intervalo_escolar.id,ids_materias_seleccionadas,ids_estudiantes).toPromise();
    for(var i=0;i<segundo_intervalo_escolar.estudiantes.length;i++){
      for(var j=0;j<segundo_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
        segundo_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].materia=this.datos_materias_reporte.filter( m => m.id==segundo_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].id_materia)[0];
      }
    }
    estudiantes_intervalos.push(segundo_intervalo_escolar);
    var tercer_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="3")[0];
    tercer_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerBoletinCalificaciones(this.usuario.id_empresa,this.filter.gestion.id,this.filter.curso.id,tercer_intervalo_escolar.id,ids_materias_seleccionadas,ids_estudiantes).toPromise();
    for(var i=0;i<tercer_intervalo_escolar.estudiantes.length;i++){
      for(var j=0;j<tercer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
        tercer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].materia=this.datos_materias_reporte.filter( m => m.id==tercer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].id_materia)[0];
      }
    }
    estudiantes_intervalos.push(tercer_intervalo_escolar);
    await this.generarPdfBoletinCalificacionesInicial(estudiantes_intervalos,primer_intervalo_escolar,intervalo_escolar_hasta,false);
    this.blockUI.stop();
  }

  async generarPdfBoletinCalificacionesInicial(estudiantes_intervalos,primer_intervalo_escolar,intervalo_escolar_hasta,mostrar_dimensiones){
    let configuraciones_calificacion:any=await this.calificacionesService.obtenerConfiguracionCalificacion(this.filter.gestion.id,this.usuario.id_empresa).toPromise();
		let nombre_documento='BOLETIN-NOTAS-'+this.filter.gestion.nombre+'-'+this.filter.escuela.nombre+'-'+this.filter.curso.nombre+'.pdf';
    let docScoreCard = new PDFDocument({ margin: 0 });
		let stream = docScoreCard.pipe(blobStream());
    configuraciones_calificacion=this.orderPipe.transform(configuraciones_calificacion, 'orden',false);
					
    for(var studentIndex=0;studentIndex<primer_intervalo_escolar.estudiantes.length;studentIndex++){
      var numberOfCards=2//(printTracking?1:2);
      var yPage=0;
      for(var item=0;item<numberOfCards && studentIndex<primer_intervalo_escolar.estudiantes.length;item++){
        var averages=[];
        docScoreCard.font('Helvetica-Bold',13);
        docScoreCard.text("RENDIMIENTO ACADÉMICO",200,yPage+20);
        docScoreCard.fontSize(9);
        docScoreCard.text(this.filter.nivel.nombre,170,yPage+40);
        docScoreCard.fontSize(8);
        docScoreCard.image(this.usuario.empresa.imagen,500, yPage+15, {width:60, height:60});
        docScoreCard.text("UNIDAD EDUCATIVA : "+this.filter.escuela.nombre,50,yPage+60);
        //docScoreCard.text("DEPENDENCIA : "+this.filter.escuela.dependencia.nombre,50,yPage+70);
        docScoreCard.text("TURNO : "+this.filter.escuela.turno.nombre,50,yPage+80);
        
        docScoreCard.text("GESTIÓN : "+this.filter.gestion.nombre,200,yPage+70);
        docScoreCard.text("AÑO DE ESCOLARIDAD : "+this.filter.curso.nombre,200,yPage+80);
        
        //docScoreCard.text('CÓDIGO RUDE : '+primer_intervalo_escolar.estudiantes[studentIndex].codigo_rude,40,yPage+100);
        docScoreCard.text('APELLIDOS Y NOMBRES : '+primer_intervalo_escolar.estudiantes[studentIndex].persona.nombre_completo,200,yPage+100);
        docScoreCard.rect(30,yPage+95,545,20).stroke();
        //docScoreCard.text('ÁREA',46,yPage+125);
        //docScoreCard.rect(45,yPage+115,25,30).stroke();
        docScoreCard.text('CAMPOS DE SABERES Y CONOCIMIENTOS',60,yPage+125);
        docScoreCard.rect(30,yPage+115,230,30).stroke();
        docScoreCard.text('VALORACIÓN CUALITATIVA',340,yPage+118);
        docScoreCard.rect(260,yPage+115,315,11).stroke();
        docScoreCard.text('TRIMESTRE',370,yPage+128);
        docScoreCard.rect(260,yPage+126,315,10).stroke();

        var totalFieldNumber=1;
        for(var i=0;i<configuraciones_calificacion.length;i++){
          totalFieldNumber=totalFieldNumber+configuraciones_calificacion[i].numero_casillas+2;
        }
        let finalScores=primer_intervalo_escolar.estudiantes[0].inscripciones_colegio[0].calificaciones.filter( c => c.orden==totalFieldNumber );
        Util.ordernarMaterias(finalScores);
        var x=260,xRect=260,incremento=105;
        for(var b=0;b<estudiantes_intervalos.length;b++){
          docScoreCard.text(estudiantes_intervalos[b].nombre_corto+"º",x+40,yPage+138);
          docScoreCard.rect(xRect,yPage+136,incremento,9).stroke();
          x=x+incremento;
          xRect=xRect+incremento;
        }
        docScoreCard.font('Helvetica');
        var y=152,showAnualAverage=true;

        docScoreCard.font('Helvetica-Bold');
        docScoreCard.text("COMUNIDAD Y SOCIEDAD",35,yPage+y);
        docScoreCard.font('Helvetica');
        docScoreCard.text("DESARROLLO DE LA COMUNICACIÓN, LENGUAJES Y ARTES (MÚSICA, ARTES PLÁSTICAS Y VISUALES, CIENCIAS SOCIALES-RECREACIÓN).",35,yPage+y+10,{width:200});
        docScoreCard.font('Helvetica-Bold');
        docScoreCard.text("CIENCIA TECNOLOGÍA Y PRODUCCIÓN",35,yPage+y+50);
        docScoreCard.font('Helvetica');
        docScoreCard.text("DESARROLLO DEL CONOCIMIENTO Y DE LA PRODUCCIÓN (MATEMÁTICA-TÉCNICA TECNOLÓGICA)",35,yPage+y+60,{width:200});
        docScoreCard.font('Helvetica-Bold');
        docScoreCard.text("VIDA TIERRA Y TERRITORIO",35,yPage+y+100);
        docScoreCard.font('Helvetica');
        docScoreCard.text("DESARROLLO BIO SICOMOTRIZ (CIENCIAS NATURALES)",35,yPage+y+110,{width:200});
        docScoreCard.font('Helvetica-Bold');
        docScoreCard.text("COSMOS Y PENSAMIENTO",35,yPage+y+150);
        docScoreCard.font('Helvetica');
        docScoreCard.text("DESARROLLO SOCIOCULTURAL, AFECTIVO Y ESPIRITUAL.",35,yPage+y+160,{width:200});
        docScoreCard.rect(30,yPage+y-7,230,190).stroke();
        docScoreCard.rect(xRect,yPage+y-7,incremento).stroke();

        y=152,x=260,xRect=260;
        docScoreCard.fontSize(7);
        for(let j=0;j<estudiantes_intervalos.length;j++){
          y=152;
          if(estudiantes_intervalos[j].estudiantes.length>0){
            let finalStudentScores=estudiantes_intervalos[j].estudiantes[studentIndex].inscripciones_colegio[0].calificaciones;
            Util.ordernarMaterias(finalStudentScores);
            for(var i=0;i<finalStudentScores.length;i++){
              docScoreCard.text(finalStudentScores[i].descripcion?finalStudentScores[i].descripcion.toUpperCase().trim():"",x+2,yPage+y+5,{width:100});
              docScoreCard.rect(xRect,yPage+y-7,incremento,190).stroke();
              y=y+15;
              docScoreCard.fillColor('black');
            }

          }

          x=x+incremento;
          xRect=xRect+incremento;
        }

        var yF=152;
        var sum;	
        
        docScoreCard.fillColor('black');
        //if(!printTracking){
          if(item==0){
            studentIndex++;
            yPage=yPage+396;
          }else{
            docScoreCard.addPage();
          }
        /*}else{
          if(primer_intervalo_escolar.estudiantes[studentIndex].trackings.length>0){
            $scope.getStudentTrackingPDF(docScoreCard,management,school,grade,primer_intervalo_escolar.estudiantes[studentIndex],y);
          }
          docScoreCard.addPage();
        }*/
      }		
    }

    docScoreCard.end();
    this.blockUI.stop();
    stream.on('finish', function() {
      var fileURL = stream.toBlobURL('application/pdf');
      //Util.descargarDocumento(nombre_documento,fileURL);
      window.open(fileURL,'_blank','location=no');
    });
  }

  abrirPopuDocumentosEstudiante(estudiante){
    this.documentos_estudiante_materia_modal = this.modalService.open(DocumentosEstudianteComponent, {windowClass:'documentos-estudiante',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
		this.documentos_estudiante_materia_modal.componentInstance.estudiante = estudiante;
    this.documentos_estudiante_materia_modal.componentInstance.usuario = this.usuario;
		
		this.documentos_estudiante_materia_modal.componentInstance.onConfirm.subscribe(($e) => {
			this.documentos_estudiante_materia_modal.close();
    });
  }

  abrirPopupAsuntoAdministrativo(estudiante){
    this.documentos_estudiante_materia_modal = this.modalService.open(RegistroAsuntoAdministrativoComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
		this.documentos_estudiante_materia_modal.componentInstance.estudiante = estudiante;
    this.documentos_estudiante_materia_modal.componentInstance.usuario = this.usuario;
		
		this.documentos_estudiante_materia_modal.componentInstance.alTerminar.subscribe(($e) => {
			this.documentos_estudiante_materia_modal.close();
    });
  }

  async generarPromediosCalificaciones(){
    this.blockUI.start();
    var estudiantes_intervalos=[];
    var primer_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="1")[0];
    primer_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerReportePromediosCalificaciones(this.filter.gestion.id,this.filter.curso.id,primer_intervalo_escolar.id,this.filter.materias).toPromise();
    for(var i=0;i<primer_intervalo_escolar.estudiantes.length;i++){
      for(var j=0;j<primer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
        primer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].materia=this.datos_materias_reporte.filter( m => m.id==primer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].id_materia)[0];
      }
    }
    estudiantes_intervalos.push(primer_intervalo_escolar);
    var segundo_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="2")[0];
    segundo_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerReportePromediosCalificaciones(this.filter.gestion.id,this.filter.curso.id,segundo_intervalo_escolar.id,this.filter.materias).toPromise();
    for(var i=0;i<segundo_intervalo_escolar.estudiantes.length;i++){
      for(var j=0;j<segundo_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
        segundo_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].materia=this.datos_materias_reporte.filter( m => m.id==segundo_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].id_materia)[0];
      }
    }
    estudiantes_intervalos.push(segundo_intervalo_escolar);
    var tercer_intervalo_escolar=this.filter.tipo_intervalo.clases.filter( i => i.nombre_corto =="3")[0];
    tercer_intervalo_escolar.estudiantes=await this.reporteCursoService.obtenerReportePromediosCalificaciones(this.filter.gestion.id,this.filter.curso.id,tercer_intervalo_escolar.id,this.filter.materias).toPromise();
    for(var i=0;i<tercer_intervalo_escolar.estudiantes.length;i++){
      for(var j=0;j<tercer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones.length;j++){
        tercer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].materia=this.datos_materias_reporte.filter( m => m.id==tercer_intervalo_escolar.estudiantes[i].inscripciones_colegio[0].calificaciones[j].id_materia)[0];
      }
    }
    estudiantes_intervalos.push(tercer_intervalo_escolar);
    await this.generarExcelReportePromediosCalificaciones(estudiantes_intervalos,primer_intervalo_escolar);
    this.blockUI.stop();
  }

  async generarExcelReportePromediosCalificaciones(estudiantes_intervalos,primer_intervalo_escolar){
    let nombre_documento='PROMEDIOS-'+this.filter.gestion.nombre+'-'+this.filter.escuela.nombre+'-'+this.filter.curso.nombre+'.xlsx';
    var data = [["","REPORTE DE PROMEDIOS CURSO"],
          ["",""],
          ["","GESTIÓN:"+this.filter.gestion.nombre],
          ["","UNIDAD EDUCATIVA:"+this.filter.escuela.nombre],
          ["","CURSO:"+this.filter.curso.nombre],
          ["",""],
          ["","TRIMESTRE PRIMERO","","","","","TRIMESTRE SEGUNDO","","","","","TRIMESTRE TERCERO","","","","","PROMEDIO ANUAL"]];
    var headerColumns=[];
    headerColumns.push("Nº");			
    headerColumns.push("APELLIDOS Y NOMBRES");	
    headerColumns.push("PROMEDIO DECIMAL");	
    headerColumns.push("PROMEDIO REDONDEADO");	
    headerColumns.push("");	
    headerColumns.push("Nº");			
    headerColumns.push("APELLIDOS Y NOMBRES");	
    headerColumns.push("PROMEDIO DECIMAL");	
    headerColumns.push("PROMEDIO REDONDEADO");	
    headerColumns.push("");	
    headerColumns.push("Nº");			
    headerColumns.push("APELLIDOS Y NOMBRES");	
    headerColumns.push("PROMEDIO DECIMAL");	
    headerColumns.push("PROMEDIO REDONDEADO");	
    headerColumns.push("");	
    headerColumns.push("Nº");			
    headerColumns.push("APELLIDOS Y NOMBRES");	
    headerColumns.push("PROMEDIO DECIMAL");	
    headerColumns.push("PROMEDIO REDONDEADO");	
    headerColumns.push("");	
    data.push(headerColumns);

    let calificaciones_intervalos_escolares=[];
    for(let j=0;j<estudiantes_intervalos.length;j++){
      for(let k=0;k<estudiantes_intervalos[j].estudiantes.length;k++){
        let calificaciones_materias = estudiantes_intervalos[j].estudiantes[k].inscripciones_colegio[0].calificaciones.filter((calificacion, i, arr) => arr.findIndex(t => t.id_materia === calificacion.id_materia) === i);
        calificaciones_materias=Util.completarMaterias(this.datos_materias_reporte,calificaciones_materias);
        let finalStudentScores=[];
        for(let ic=0;ic<calificaciones_materias.length;ic++){
          let calificaciones_materia=estudiantes_intervalos[j].estudiantes[k].inscripciones_colegio[0].calificaciones;
          calificaciones_materia=Util.completarMaterias(this.datos_materias_reporte,calificaciones_materia);
          calificaciones_materia=estudiantes_intervalos[j].estudiantes[k].inscripciones_colegio[0].calificaciones.filter( c=>(c.id_materia==calificaciones_materias[ic].id_materia));
          let calificacion_final_materia = calificaciones_materia.reduce(((prev, current) => (prev.orden > current.orden) ? prev : current),0);
          if(calificacion_final_materia){
            finalStudentScores.push(calificacion_final_materia);
          }
        }
        Util.ordernarMaterias(finalStudentScores);
        estudiantes_intervalos[j].estudiantes[k].inscripciones_colegio[0].calificaciones=finalStudentScores;
        //calificaciones_intervalos_escolares.push(finalStudentScores);
      }
    }
    estudiantes_intervalos=this.calcularPromediosEstudiantes(estudiantes_intervalos,this.filter.opcion_reporte_promedios.id);
    estudiantes_intervalos.push(JSON.parse(JSON.stringify(primer_intervalo_escolar)));
    //calculo de los promedios finales
    for(let studentIndex=0;studentIndex<primer_intervalo_escolar.estudiantes.length;studentIndex++){
      if(estudiantes_intervalos[3].estudiantes.length>0){
        var promedio_general=0;
        var estudiante=estudiantes_intervalos[3].estudiantes[studentIndex];
        if(estudiantes_intervalos[0].estudiantes.length>0){
          var estudiante_primer=estudiantes_intervalos[0].estudiantes.filter(e => e.id == estudiante.id)[0];
          promedio_general=promedio_general+estudiante_primer.averageWithDecimals;
        }
        if(estudiantes_intervalos[1].estudiantes.length>0){
          var estudiante_segundo=estudiantes_intervalos[1].estudiantes.filter(e => e.id == estudiante.id)[0];
          promedio_general=promedio_general+estudiante_segundo.averageWithDecimals;
        }
        if(estudiantes_intervalos[2].estudiantes.length>0){
          var estudiante_tercer=estudiantes_intervalos[2].estudiantes.filter(e => e.id == estudiante.id)[0];
          promedio_general=promedio_general+estudiante_tercer.averageWithDecimals;
        }
        estudiantes_intervalos[3].estudiantes[studentIndex].averageRounded=Math.round(promedio_general/3);
        estudiantes_intervalos[3].estudiantes[studentIndex].averageWithDecimals=Math.round((promedio_general/3)*100)/100;
      }
    }
    if(this.filter.opcion_reporte_promedios.id==0){
      this.ordenarPromediosEstudiantes(estudiantes_intervalos[3].estudiantes);
    }


    for(var studentIndex=0;studentIndex<primer_intervalo_escolar.estudiantes.length;studentIndex++)
    {
      var columns=[];
      if(estudiantes_intervalos[0].estudiantes.length>0){
        columns.push(studentIndex+1);
        columns.push(estudiantes_intervalos[0].estudiantes[studentIndex].persona.nombre_completo);
        columns.push(estudiantes_intervalos[0].estudiantes[studentIndex].averageWithDecimals);
        columns.push(estudiantes_intervalos[0].estudiantes[studentIndex].averageRounded);
        columns.push("");
      }else{
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
      }
      if(estudiantes_intervalos[1].estudiantes.length>0){
        columns.push(studentIndex+1);
        columns.push(estudiantes_intervalos[1].estudiantes[studentIndex].persona.nombre_completo);
        columns.push(estudiantes_intervalos[1].estudiantes[studentIndex].averageWithDecimals);
        columns.push(estudiantes_intervalos[1].estudiantes[studentIndex].averageRounded);
        columns.push("");
      }else{
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
      }
      if(estudiantes_intervalos[2].estudiantes.length>0){
        columns.push(studentIndex+1);
        columns.push(estudiantes_intervalos[2].estudiantes[studentIndex].persona.nombre_completo);
        columns.push(estudiantes_intervalos[2].estudiantes[studentIndex].averageWithDecimals);
        columns.push(estudiantes_intervalos[2].estudiantes[studentIndex].averageRounded);
        columns.push("");
      }else{
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
      }
      if(estudiantes_intervalos[3].estudiantes.length>0){
        columns.push(studentIndex+1);
        columns.push(estudiantes_intervalos[3].estudiantes[studentIndex].persona.nombre_completo);
        columns.push(estudiantes_intervalos[3].estudiantes[studentIndex].averageWithDecimals);
        columns.push(estudiantes_intervalos[3].estudiantes[studentIndex].averageRounded);
        columns.push("");
      }else{
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
        columns.push("");
      }
      /*columns.push(studentIndex+1);
      columns.push(studentsBimester[3].students[studentIndex].person.name);
      columns.push(studentsBimester[3].students[studentIndex].averageWithDecimals);
      columns.push(studentsBimester[3].students[studentIndex].averageRounded);
      columns.push("");*/
      
      data.push(columns);
    }
    
    let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("REPORTE");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol1 = worksheet.getColumn(1);
		dobCol1.width = 5;
    const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 40;
		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 10;
    const dobCol4 = worksheet.getColumn(4);
		dobCol4.width = 10;

    const dobCol6 = worksheet.getColumn(6);
		dobCol6.width = 5;
    const dobCol7 = worksheet.getColumn(7);
		dobCol7.width = 40;
		const dobCol8 = worksheet.getColumn(8);
		dobCol8.width = 10;
    const dobCol9 = worksheet.getColumn(9);
		dobCol9.width = 10;

    const dobCol11 = worksheet.getColumn(11);
		dobCol11.width = 5;
    const dobCol12 = worksheet.getColumn(12);
		dobCol12.width = 40;
		const dobCol13 = worksheet.getColumn(13);
		dobCol13.width = 10;
    const dobCol14 = worksheet.getColumn(14);
		dobCol14.width = 10;

    const dobCol16 = worksheet.getColumn(16);
		dobCol16.width = 5;
    const dobCol17 = worksheet.getColumn(17);
		dobCol17.width = 40;
		const dobCol18 = worksheet.getColumn(18);
		dobCol18.width = 10;
    const dobCol19 = worksheet.getColumn(19);
		dobCol19.width = 10;
    

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		  fs.saveAs(blob, nombre_documento);
		})
		this.blockUI.stop();
  }

  calcularPromediosEstudiantes(estudiantes_intervalos,orden_lista){
		for(var i=0;i<estudiantes_intervalos.length;i++){
			for(var j=0;j<estudiantes_intervalos[i].estudiantes.length;j++){
				let sum=0,counter=0;
				for(var k=0;k<estudiantes_intervalos[i].estudiantes[j].inscripciones_colegio[0].calificaciones.length;k++){

					/*if(estudiantes_intervalos[i].estudiantes[j].inscripciones_colegio[0].calificaciones[k].materia.area){
            console.log(!estudiantes_intervalos[i].estudiantes[j].inscripciones_colegio[0].calificaciones[k].materia.area.checked);
						if(!estudiantes_intervalos[i].estudiantes[j].inscripciones_colegio[0].calificaciones[k].materia.area.checked){
							var res=this.checkAreaList(estudiantes_intervalos[i].estudiantes[j].inscripciones_colegio[0].calificaciones,estudiantes_intervalos[i].estudiantes[j].inscripciones_colegio[0].calificaciones[k]);
              console.log(res);
							if (res.length>1) {
								var sumAreas=0;
								for (var index = 0; index < res.length; index++) {
									sumAreas=sumAreas+res[index];
								}
								sum=sum+(Math.round(sumAreas/res.length));
							}else{
								sum=sum+res[0];
							}
							counter++;
						}
					}else{*/
						sum=sum+(estudiantes_intervalos[i].estudiantes[j].inscripciones_colegio[0].calificaciones[k].valor?estudiantes_intervalos[i].estudiantes[j].inscripciones_colegio[0].calificaciones[k].valor:0);
						counter++;	
					//}
					//sum=sum+studentsBimester[i].students[j].inscriptions[0].scores[k].value;
				}
				estudiantes_intervalos[i].estudiantes[j].averageRounded=Math.round(sum/counter);
				estudiantes_intervalos[i].estudiantes[j].averageWithDecimals=Math.round((sum/counter)*100)/100;
			}

			if(orden_lista==0){
				this.ordenarPromediosEstudiantes(estudiantes_intervalos[i].estudiantes);
			}
		}
		return estudiantes_intervalos;
	}

  checkAreaList(calificaciones,calificacion){
		var res=[];
		if(calificacion.materia.area){
			for(var i=0;i<calificaciones.length;i++){
				if(calificaciones[i].materia.area && calificaciones[i].materia.area.id==calificacion.materia.area.id){
					//calificaciones[i].materia.area.checked=true;
          calificacion.materia.area.checked=true;
					res.push(calificaciones[i].valor);
				}
			}
		}else{
			res.push(calificacion.valor);
		}
		return res;
	}

  ordenarPromediosEstudiantes(estudiantes){
		estudiantes.sort(function(a, b){
			var sortStatus = 0;
			if (a.averageWithDecimals < b.averageWithDecimals) {
				sortStatus = 1;
			} else if (a.averageWithDecimals > b.averageWithDecimals) {
					sortStatus = -1;
			}
			return sortStatus;
		});
	}

  descargarExcelImportacionPagos(){
		this.blockUI.start();
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Importacion_pagos");
    var documentName='EJEMPLO-IMPORTACIÓN-PAGOS-'+this.filter.curso.nombre+'.xlsx';
		var data = [["","IMPORTACIÓN DE PAGOS"],
						["",""],
						["","GESTIÓN:"+this.filter.gestion.nombre],
						["","UNIDAD EDUCATIVA:"+this.filter.escuela.nombre],
						["","CURSO:"+this.filter.curso.nombre],
						["",""]];
			var headerColumns=[],paymentColumns=[],columsWidth=[],dataS=[];
			headerColumns.push("Nº");			
      headerColumns.push("CÓDIGO ESTUDIANTE");			
			headerColumns.push("APELLIDOS Y NOMBRES ESTUDIANTE");	
			paymentColumns.push("");
			paymentColumns.push("");
      paymentColumns.push("");
			let conceptos_pagos=[];
      for(let i=0;this.filter.conceptos_importacion && i<this.filter.conceptos_importacion.length;i++){
        conceptos_pagos.push(this.conceptos_pagos.filter(cp => cp.id==this.filter.conceptos_importacion[i])[0]);
      }
      conceptos_pagos=this.orderPipe.transform(conceptos_pagos, 'id',false);
			for(var i=0;i<conceptos_pagos.length;i++){
				paymentColumns.push(conceptos_pagos[i].concepto.nombre_corto+"-"+conceptos_pagos[i].concepto.nombre);
        let columna=4;
				for(var j=1;j<=conceptos_pagos[i].cuotas;j++){
					headerColumns.push(j+" "+conceptos_pagos[i].concepto.nombre);	
					columsWidth.push({wch:4});
          let columna_ancho= worksheet.getColumn(columna);
          columna_ancho.width = 5;
          columna++;
					if(j>1){
						paymentColumns.push(null);
					}
				}
			}
			data.push(paymentColumns);
			data.push(headerColumns);

			for(var j=0;j<this.estudiantes.length;j++){
				var columns=[];
				columns.push((j+1));
        columns.push(this.estudiantes[j].codigo);
				columns.push(this.estudiantes[j].persona.nombre_completo);		
				data.push(columns);
			}

      data.forEach(d => {
      let row = worksheet.addRow(d);
      }
      );

      const dobCol1 = worksheet.getColumn(1);
      dobCol1.width = 5;
      const dobCol2 = worksheet.getColumn(2);
      dobCol2.width = 10;
      const dobCol3 = worksheet.getColumn(3);
      dobCol3.width = 40;

      workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, documentName);
      })
      this.blockUI.stop();
	}

  subirExcelImportacionPagos(event){
		this.importacion_pagos_estudiantes.estudiantes=[];
	  let files = event.target.files;
	  let i,f,me=this;
	  for (i = 0, f = files[i]; i != files.length; ++i) {
      let reader = new FileReader();
		  let name = f.name;
      reader.onload = function(e) {
        me.blockUI.start();
        let data = e.target.result;

        let workbook = XLSX.read(data, {type: 'binary'});
        let first_sheet_name = workbook.SheetNames[0];
        let address_of_cell = 'C19';
        let row=9;
        let worksheet = workbook.Sheets[first_sheet_name];
        do {
          let column=3;
          let persona={
            numero:worksheet['A'+row]!=undefined && worksheet['A'+row]!=""?worksheet['A'+row].v.toString():"",
            codigo_estudiante:worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():"",
            nombre:worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():"",
            pagos:[],
          }
          let payment_concept_code;
          do{
            let pago:any={};
            payment_concept_code=worksheet[GlobalVariable.Dictionary.LETRAS_EXCEL[column]+"7"]!=undefined?worksheet[GlobalVariable.Dictionary.LETRAS_EXCEL[column]+"7"].v.toString().split("-")[0]:payment_concept_code;
            pago.concepto_pago=me.conceptos_pagos.filter(cp => cp.concepto.nombre_corto==payment_concept_code)[0];
            pago.cuota=parseInt(worksheet[GlobalVariable.Dictionary.LETRAS_EXCEL[column]+"8"].v.toString().split(" ")[0]);
            pago.importe=pago.concepto_pago.importe;
            pago.pagado=parseFloat((worksheet[GlobalVariable.Dictionary.LETRAS_EXCEL[column]+row]!=undefined && worksheet[GlobalVariable.Dictionary.LETRAS_EXCEL[column]+row]!="")?worksheet[GlobalVariable.Dictionary.LETRAS_EXCEL[column]+row].v.toString():0);
            pago.pagado=Number.isNaN(pago.pagado)?0:pago.pagado;
            pago.saldo=pago.concepto_pago.importe-pago.pagado;
            persona.pagos.push(pago);
            column++
          }while(worksheet[GlobalVariable.Dictionary.LETRAS_EXCEL[column]+"8"]!=undefined && worksheet[GlobalVariable.Dictionary.LETRAS_EXCEL[column]+"8"]!="");
          me.importacion_pagos_estudiantes.estudiantes.push(persona);
          row++;			   
        } while (worksheet['A'+row]!=undefined);
        me.blockUI.stop();
        me.importacion_pagos_estudiantes_modal=me.modalService.open(me.importacion_pagos_estudiantes_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
        me.importacion_pagos_estudiantes_modal.result.then((result) => {
          me.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
        });       
      };
		  reader.readAsBinaryString(f);
	  }
	}

  guardarImportacionPagosEstudiantes(){
    this.blockUI.start();
    this.reporteCursoService.guardarImportacionPagosEstudiantes({estudiantes:this.importacion_pagos_estudiantes.estudiantes,id_escuela:this.filter.escuela.id}).subscribe((res:any)=>{
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.toastr.success(res.mensaje);
        this.importacion_pagos_estudiantes_modal.close();
      }
      this.blockUI.stop();
    });
  }

  abrirRegistroInscripcionMasiva(){
    this.filtro_masivo.gestion=null;
    this.filtro_masivo.nivel=null;
    this.filtro_masivo.curso=null;
    this.inscripciones_masivas=JSON.parse(JSON.stringify(this.estudiantes));
    this.inscripcion_masiva_modal=this.modalService.open(this.inscripcion_masiva_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
    this.inscripcion_masiva_modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });       
  }

  obtenerGestionesMasivo(){
    let gestiones_futuras=[];
		if(this.filter.gestiones){
			gestiones_futuras=this.filter.gestiones.filter(g => parseFloat(g.nombre) > parseFloat(this.filter.gestion.nombre));
			gestiones_futuras=gestiones_futuras.length>1?[]:gestiones_futuras;
		}
		return gestiones_futuras;
  }

  async obtenerCursosPorNivelMasivo(id_nivel_escolar){
    this.filtro_masivo.cursos=[];
    let cursos:any=await this.cursosService.obtenerCursosNivel(this.usuario.id_empresa,id_nivel_escolar).toPromise();
    this.filtro_masivo.cursos=this.filtro_masivo.cursos.concat(cursos);
    this.filtro_masivo.curso=null;
  }

  eliminarInscripcionMasivaEstudiante(estudiante){
		this.inscripciones_masivas.splice(this.inscripciones_masivas.indexOf(estudiante), 1);
	}

	agregarInscripcionMasivaEstudiante(estudiante){
		let existe_estudiante=this.inscripciones_masivas.filter(e => e.id == estudiante.id).length>0;
		if(!existe_estudiante){
			this.inscripciones_masivas.push(estudiante);
      this.toastr.info("Estudiante agregado exitosamente!");
		}else{
      this.toastr.warning("El estudiante ya fue agregado!");
		}
	}

  abrirPopupConfirmacionEliminacionRegistroMasivo(){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = '¿Esta seguro guardar el registro de inscripcion? ¡Verifique que los datos esten correctamente ya que esta operación es irreversible!';
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.guardarRegistroMasivoInscripcion();
			}
			this.popupConfirmacion.close();
        });
	}

  validarRegistroMasivoInscripcion(){
    let res=true;
    if(!this.filtro_masivo.gestion){
      res=res && false;
      this.toastr.error("Debe especificar la gestion!");
    }
    if(!this.filtro_masivo.nivel){
      res=res && false;
      this.toastr.error("Debe especificar el nivel!");
    }
    if(!this.filtro_masivo.curso){
      res=res && false;
      this.toastr.error("Debe especificar el curso!");
    }
    if(this.inscripciones_masivas.length==0){
      res=res && false;
      this.toastr.error("Debe especificar al menos un estudiante en el registro!");
    }
    return res;
  }

  guardarRegistroMasivoInscripcion(){
    if(this.validarRegistroMasivoInscripcion()){
      this.blockUI.start();
      let inscripcion_masiva={
        estudiantes:this.inscripciones_masivas,
        id_empresa:this.usuario.id_empresa,
        escuela:this.filter.escuela,
        gestion:this.filtro_masivo.gestion,
        nivel:this.filtro_masivo.nivel,
        curso:this.filtro_masivo.curso
      };
      this.reporteCursoService.guardarRegistroInscripcionMasiva(inscripcion_masiva).subscribe((res:any)=>{
        this.blockUI.stop();
        if(res.tiene_error){
          this.toastr.error(res.mensaje);
        }else{
          this.toastr.success(res.mensaje);
          this.inscripcion_masiva_modal.close();
        }
      });
    }
  }

  verEstudiante(estudiante){
    this.blockUI.start();
    this.estudiantesService.obtenerEstudiante(estudiante.id).subscribe((res:any)=>{
      this.blockUI.stop();
      this.estudiante=res;
		  this.abrirVerEstudiante();
    });
  }

  abrirVerEstudiante(){
		this.edicion_estudiante = this.modalService.open(VistaEstudianteComponent, {scrollable:true,windowClass : "vista_estudiante",ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.edicion_estudiante.componentInstance.estudiante = this.estudiante;
    this.edicion_estudiante.componentInstance.usuario = this.usuario;
		
		this.edicion_estudiante.componentInstance.onConfirm.subscribe(($e) => {
			this.edicion_estudiante.close();
    });
  }

}
