<div class="page-content container">
    <div class="page-header">
      <h1 class="page-title text-primary-d2">
        Empleados
        <small class="page-info text-secondary-d2">
          <i class="fa fa-angle-double-right text-80"></i>
          Lista
        </small>
      </h1>
    </div>

    

    <div class="bgc-primary-d1 text-white px-3 py-25">
      <span class="text-90">Lista de</span>
      Empleados
    </div>

    <div class="bootstrap-table bootstrap4">
      <div class="fixed-table-toolbar">
        <div class="row">
          <div class="col-12 col-md-9">
			  <div class="row">
				<div class="col-12 col-md-12">
					<app-paginator-header [paginator]="this"></app-paginator-header>
				</div>
			  </div>
		  </div>
          <div class="col-12 col-md-3">
            <div class="columns columns-right btn-group float-right">
              <button (click)="getSearch(text_search,null)" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                <i class="fa fa fa-search"></i>
			  </button>
			  <button *ngIf="aplicacion.puede_crear" title="Nuevo" (click)="crearNuevoEmpleado()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 bs-print" type="button">
                <i class="fa fa-plus text-purple-d1"></i>
              </button>
              <button *ngIf="usuario.empresa.usar_biometrico_asistencia" title="Turnos" (click)="verTurnos()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-warning btn-a-outline-warning py-1 bs-print" type="button">
                <i class="fas fa-hourglass-half text-warning-d1"></i>
              </button>
              <button *ngIf="usuario.empresa.usar_biometrico_asistencia" title="Asistencia" (click)="abrirAsistencia()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-danger btn-a-outline-danger py-1 bs-print" type="button">
                <i class="fa fa-calendar text-danger-d1"></i>
              </button>
			  <div class="keep-open btn-group show" title="Columns">
                <button class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-label="Columns" title="Acciones" aria-expanded="true">
					<i class="fa fa-wrench text-blue-d1"></i>
					<span class="caret"></span>
				</button>

				<div class="dropdown-menu dropdown-menu-right enable-columns-bar" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-137px, 37px, 0px);" x-placement="bottom-end">
					<div class="col-12 col-sm-12 mt-12 mt-sm-0 cards-container">
						<div class="card bgc-primary brc-primary radius-0">
						  <div class="card-header">
							<h5 class="card-title text-white font-light"><i class="fa fa-table mr-2px"></i> Acciones</h5>
						  </div>
	  
						  <div class="card-body p-0 bg-white">
							<table class="table table-striped table-hover mb-0">
							  <tbody>
								<tr *ngIf="aplicacion.puede_modificar">
									<td class="text-dark-m2">
									Configuración Aplicación
									</td>
									<td class="text-dark-m2">
										<a (click)="abrirConfiguracionAplicacion()" href="javascript:void(0)" class="text-blue"><i class="fa fa-cog text-primary"></i></a>
									</td>
								</tr>
							  </tbody>
							</table>
						  </div>
						</div>
					  </div>
				</div>
				<app-page-configuration [page_configuration]="configuracion_pagina"></app-page-configuration>
			</div>
            </div>
          </div>
		</div>
      </div>

      <div class="fixed-table-container" style="padding-bottom: 0px;">
        <div class="fixed-table-header" style="display: none;">
          <table></table>
        </div>
        <div id="gallery" class="fixed-table-body">
          <div class="fixed-table-loading table table-bordered table-hover" style="top: 46.6167px;">
            <span class="loading-wrap">
              <span class="animation-wrap">
                <span class="animation-dot"></span>
              </span>
            </span>
          </div>
          <table *ngIf="empleados.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped" id="table">
            <thead class="bgc-white text-grey text-uppercase text-80">
              <tr>
                <th class="detail" rowspan="1">
                  <div class="th-inner ">Nº</div>
                </th>
                <th id="cabecera_codigo" class="center" *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='codigo' && this.direction=='asc','desc':this.column=='codigo' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('codigo')">Código</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().apellido_paterno.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='apellido_paterno' && this.direction=='asc','desc':this.column=='apellido_paterno' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('identificacion')">Apellido Paterno</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().apellido_materno.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='apellido_materno' && this.direction=='asc','desc':this.column=='apellido_materno' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('apellido_materno')">Apellido Materno</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().nombres.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='nombres' && this.direction=='asc','desc':this.column=='nombres' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('nombres')">Nombres</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th *ngIf="configuracion_pagina.getConfiguration().imagen.show" class="center">
                    Fotografia
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().ci.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='ci' && this.direction=='asc','desc':this.column=='ci' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('ci')">C.I.</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th class="center" *ngIf="configuracion_pagina.getConfiguration().activo.show">
                    <div class="th-inner sortable both " [ngClass]="{'asc':this.column=='activo' && this.direction=='asc','desc':this.column=='ci' && this.direction=='desc'}">
                        <a href="javascript:void(0)" (click)="this.sortColumn('activo')">Activo</a>
                    </div><div class="fht-cell"></div>
                </th>
                <th style="text-align: center; width: 140px; " data-field="tools">
                  <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                  <div class="fht-cell"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr #empleados *ngFor="let empleado of empleados; let i=index;">
                <td>
                  {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
                </td>
                <td rel="cabecera_codigo" *ngIf="configuracion_pagina.getConfiguration().codigo.show">
                    {{empleado.codigo}}
                </td>
                <td rel="cabecera_nombre" *ngIf="configuracion_pagina.getConfiguration().apellido_paterno.show">
                    {{empleado.apellido_paterno}}
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().apellido_materno.show">{{empleado.apellido_materno}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().nombres.show">{{empleado.nombres}}</td>
                <td *ngIf="configuracion_pagina.getConfiguration().imagen.show">
                    <div class="imagen-empleado-vista clearfix">
                        <a href="{{rest_server+empleado.imagen}}" title="{{empleado.nombre_completo}}" data-rel="colorbox">
                            <img width="50" height="50" alt="sin imagen" src="{{rest_server+empleado.imagen}}" />
                        </a>
                    </div>
                </td>
                <td *ngIf="configuracion_pagina.getConfiguration().ci.show">{{empleado.ci}}</td>
                <td *ngIf="empleado.activo==1 && configuracion_pagina.getConfiguration().activo.show"><span class="badge badge-sm badge-success">SI</span></td>
                <td *ngIf="empleado.activo==0 && configuracion_pagina.getConfiguration().activo.show"><span class="badge badge-sm badge-danger">NO</span></td>
                <td style="text-align: center; width: 140px; ">
                  <div class="action-buttons">        
                    <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarEmpleado(empleado)">          
                      <i class="fa fa-pencil-alt text-105"></i>        
					</a>
                    <a *ngIf="usuario.empresa.usar_almacenes_empleado" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="verAlmacen(empleado)">          
                        <i class="fas fa-warehouse text-105"></i>        
                      </a>
                    <a *ngIf="usuario.empresa.usar_almacenes_empleado" title="Pagar Sueldo" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="pagarSueldo(empleado)">
                        <i class="fas fa-money-bill-alt"></i>
                    </a>
                    <a *ngIf="usuario.empresa.usar_biometrico_asistencia" title="Turnos del empleado" (click)="modificarAsignacionTurnoEmpleado(empleado)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                        <i class="fas fa-user-clock text-105"></i>
                    </a>
                    <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacion(empleado)">          
                      <i class="fa fa-trash-alt text-105"></i>        
                    </a>      
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="fixed-table-footer">
          <table><thead><tr></tr></thead></table>
        </div>
      </div>
      <div class="fixed-table-pagination">
        <app-paginator-footer *ngIf="empleados.length>0" class="pagination-detail" [paginator]="this"></app-paginator-footer>
      </div>
    </div>
    <div class="clearfix"></div>
</div>

<ng-template #modal_empleado_modificacion let-modal>
  <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <span class="text-blue text-125">Registro Empleado</span>
  
            <div class="card-toolbar ml-auto no-border pr-2 d-none">
              <label>
                <span class="align-middle d-block d-sm-inline">Validation:</span>
                <input autocomplete="off" type="checkbox" id="id-validate" class="float-right text-secondary-l1 bgc-success ml-1 ace-switch ace-switch-times ace-switch-check align-middle radius-1" />
              </label>
            </div>
            <div class="card-toolbar pl-3">
              <button id="wizard-1-prev" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1" disabled>
                <i class="fa fa-chevron-left"></i>
              </button>
              <button id="wizard-1-next" type="button" class="mx-2px btn btn-outline-default btn-h-outline-primary btn-bgc-white btn-a-primary border-2 radius-1">
                <i class="fa fa-chevron-right"></i>
              </button>
              <button id="wizard-1-finish" type="button" class="d-none mx-2px px-3 btn btn-outline-success btn-h-outline-success btn-bgc-white border-2 radius-1">
                <i class="fa fa-arrow-right"></i>
              </button>
              <button (click)="modal.dismiss('Cross click')" type="button" class="mx-2px px-3 btn btn-outline-danger btn-h-outline-danger btn-bgc-white border-2 radius-1">
                  <i class="fas fa-times-circle"></i>
                </button>
            </div>
          </div>
  
          <div class="card-body px-2">
            <div id="{{wizard_edicion_empleado}}" class="d-none">
              <ul class="mx-auto">
                <li class="wizard-progressbar"></li>
                <li>
                  <a href="#step-1">
                    <span class="step-title">
                        1
                    </span>
  
                    <span class="step-title-done">
                        <i class="fa fa-check text-success-m1"></i>
                    </span>
                  </a>
                  <span class="step-description">
                          Datos Personales
                  </span>
                </li>
                <li>
                  <a href="#step-2">
                    <span class="step-title">
                        2
                    </span>
  
                    <span class="step-title-done">
                        <i class="fa fa-check text-success-m1"></i>
                    </span>
                  </a>
                  <span class="step-description">
                          Datos Complementarios
                  </span>
                </li>
                <li *ngIf="usuario.empresa.usar_cuentas_empleados">
                  <a href="#step-3">
                    <span class="step-title">
                        3
                    </span>
  
                    <span class="step-title-done">
                        <i class="fa fa-check text-success-m1"></i>
                    </span>
                  </a>
                  <span class="step-description">
                    Cuenta de Usuario
                  </span>
                </li>
              </ul>
  
              <div class="px-2 py-2 mb-4">
                <div id="step-1" class="">
                  <div class="  form-row">
                    <div class="col-2 col-form-label text-center">
                      <div class="row">
                          <div class="col-12 col-form-label">
                              Código
                          </div>
                          <div class="col-12">
                              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                  <input [disabled]="codigo_automatico" type="text" id="codigo" name="codigo" [(ngModel)]="empleado.codigo" placeholder="Código" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="col-3 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                                Apellido Paterno
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input (change)="establecerNombreAlmacen()" type="text" id="apellido_paterno" name="apellido_paterno" [(ngModel)]="empleado.persona.apellido_paterno" placeholder="Apellido Paterno" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                                Apellido Materno
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input (change)="establecerNombreAlmacen()" type="text" id="apellido_materno" name="apellido_materno" [(ngModel)]="empleado.persona.apellido_materno" placeholder="Apellido Materno" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-form-label text-center">
                        <div class="row">
                            <div [ngClass]="{'text-danger-m1':(!empleado.persona.nombres)}" class="col-12 col-form-label">
                                Nombres
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 px-0">
                                    <input (change)="establecerNombreAlmacen()" required type="text" id="nombre" name="nombre" [(ngModel)]="empleado.persona.nombres" placeholder="Nombres" [ngClass]="{'brc-danger-m1':(!empleado.persona.nombres)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                    <i *ngIf="!empleado.persona.nombres" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                                </div>
                                <div *ngIf="!empleado.persona.nombres" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                                    <div class="arrow brc-danger-m1 mt-0"></div>
                                    <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                                </div>
        
                                <span *ngIf="!empleado.persona.nombres" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="  form-row">
                    <div class="col-4 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                                Género
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <select [compareWith]="compararObjectos" class="form-control" required name="genero" [(ngModel)]="empleado.persona.genero">
                                        <option *ngIf="!empleado.id" [ngValue]="null" selected>Ninguno</option>
                                        <option [ngValue]="genero" *ngFor="let genero of generos">{{genero.nombre}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                                Número de C.I.
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="text" id="ci" name="ci" [(ngModel)]="empleado.persona.ci" placeholder="C.I." class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="  form-row">
                    <div class="col-3 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                                Fecha de Nacimiento
                            </div>
                            <div class="col-12">
                                <div class="input-group">
                                    <input id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                                            name="dp" [(ngModel)]="empleado.persona.fecha_nacimiento_texto" ngbDatepicker #d="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div class="col-3 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                                Teléfono Fijo
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="text" id="telefono" name="telefono" [(ngModel)]="empleado.persona.telefono" placeholder="Teléfono Fijo" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                                Teléfono Móvil
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="text" id="telefono_movil" name="telefono_movil" [(ngModel)]="empleado.persona.telefono_movil" placeholder="Teléfono Móvil" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                                Correo Electrónico
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="email" id="correo_electronico" name="correo_electronico" [(ngModel)]="empleado.persona.correo_electronico" placeholder="Correo Electrónico" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
  
                <div id="step-2">
                  <div class="form-row">
                    <div class="col-4 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                                Dirección Zona/Villa
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="text" id="direccion_zona" name="direccion_zona" [(ngModel)]="empleado.persona.direccion_zona" placeholder="Zona" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                                Dirección Avenida/Calle
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="text" id="direccion" name="direccion" [(ngModel)]="empleado.persona.direccion" placeholder="Dirección" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                                Dirección Número de Vivienda
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="text" id="direccion_numero" name="direccion_numero" [(ngModel)]="empleado.persona.direccion_numero" placeholder="Número" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div *ngIf="usuario.empresa.usar_biometrico_asistencia" class="col-4 col-form-label text-center">
                        <div class="row">
                            <div class="col-12 col-form-label">
                              Id Biométrico
                            </div>
                            <div class="col-12">
                                <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                    <input type="number" step="0" id="id_biometrico" name="id_biometrico" [(ngModel)]="empleado.id_biometrico" placeholder="Id Biométrico" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-form-label text-center">
                      <div class="row">
                          <div class="col-12 col-form-label">
                            Cargo
                          </div>
                          <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 px-0">
                              <select [compareWith]="compararObjectos" class="form-control" required name="cargo" [(ngModel)]="empleado.cargo">
                                  <option *ngIf="!empleado.id" [ngValue]="null" selected>Ninguno</option>
                                  <option [ngValue]="cargo" *ngFor="let cargo of cargos">{{cargo.nombre}}</option>
                              </select>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div class="col-2 col-form-label text-center">
                      <div class="row">
                          <div class="col-12 col-form-label">
                            Tarifa
                          </div>
                          <div class="col-12">
                              <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                  <input type="number" step="0.001" id="tarifa"  [(ngModel)]="empleado.tarifa" placeholder="Tarifa" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                              </div>
                          </div>
                      </div>
                    </div>
                    <div *ngIf="usuario.empresa.usar_profesores_instituto || usuario.empresa.usar_profesores_colegio" class="col-2 col-form-label text-center">
                      <div class="row">
                          <div class="col-12 col-form-label">
                            ¿Es Docente?
                          </div>
                          <div class="col-12">
                            <div class="d-inline-flex align-items-center col-12 px-0">
                              <label>
                                  <input name="switch-field-22" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="empleado.es_profesor"/>
                              </label>
                            </div>
                          </div>
                      </div>
                    </div>
                    <ng-container *ngIf="usuario.empresa.usar_almacenes_empleado">
                        <div class="col-2 col-form-label text-center">
                            <div class="row">
                                <div class="col-12 col-form-label">
                                    ¿Crear Almacen?
                                </div>
                                <div class="col-12">
                                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                        <label>
                                            <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="empleado.crear_almacen"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 col-form-label text-center" *ngIf="empleado.crear_almacen">
                            <div class="row">
                                <div class="col-12 col-form-label">
                                    Almacen
                                </div>
                                <div class="col-12">
                                    <div class="d-inline-flex align-items-center col-12 col-sm-12 px-0">
                                        <input disabled type="text" id="almacen" [(ngModel)]="empleado.almacen.nombre" placeholder="Almacen" class="form-control"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                  </div>
                  <div class="form-row">
                    <div class="col-2 col-form-label text-sm-right">
                        Fotografía
                        <img *ngIf="empleado.persona.id" src="{{rest_server+empleado.persona.imagen}}" width="100">
                    </div>
                    <div class="col-3">
                        <div class="d-inline-flex align-items-center col-12 px-0">
                            <div>
                                <input type="file" class="ace-file-input" id="ace-file-input2" multiple autocomplete="off" (change)="subirImagen($event)" />
                            </div>
                        </div>
                    </div>
                    <div class="col-7" *ngIf="empleado.es_profesor && usuario.empresa.usar_profesores_instituto">
                      <div class="form-row">
                        <div class="col-12 col-form-label text-sm-center text-grey">
                            <div class="card bgc-primary brc-primary radius-0">
                                <div class="card-header">
                                <h5 class="card-title text-white font-light">
                                    <i class="fa fa-table mr-2px"></i> Registro de Docente 
                                    <a title="Agregar" class="bgc-success btn radius-1 btn-sm btn-brc-tp btn-outline-success btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearNuevoRegistroProfesorInstituto()">
                                        <i class="ace-icon fa fa-plus bigger-130"></i>
                                    </a>
                                </h5>
                                </div>
                        
                                <div class="card-body p-0 bg-white">
                                    <table class="table table-striped table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <label>Nº</label>
                                                </th>
                                                <th>
                                                    <label>Gestión</label>
                                                </th>
                                                <th>
                                                    <label>Semestre</label>
                                                </th>
                                                <th>
                                                    <label>Carrera</label>
                                                </th>
                                                <th>
                                                    <label>Acciones</label>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let registro_instituto_profesor of empleado.registros_instituto_profesor;let i=index;">
                                                <tr *ngIf="!registro_instituto_profesor.eliminado">
                                                    <td>
                                                        {{(i+1)}}
                                                    </td>
                                                    <td>
                                                        {{registro_instituto_profesor.gestion?registro_instituto_profesor.gestion.nombre:''}}
                                                    </td>
                                                    <td>
                                                        {{registro_instituto_profesor.semestre?registro_instituto_profesor.semestre.nombre:''}}
                                                    </td>
                                                    <td>
                                                        {{registro_instituto_profesor.carrera.nombre}}
                                                    </td>
                                                    <td>
                                                        <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionRegistroProfesor(registro_instituto_profesor)">
                                                            <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-7" *ngIf="empleado.es_profesor && usuario.empresa.usar_profesores_colegio">
                        <div class="form-row">
                          <div class="col-12 col-form-label text-sm-center text-grey">
                              <div class="card bgc-primary brc-primary radius-0">
                                  <div class="card-header">
                                  <h5 class="card-title text-white font-light">
                                      <i class="fa fa-table mr-2px"></i> Registro de Docente 
                                      <a title="Agregar" class="bgc-success btn radius-1 btn-sm btn-brc-tp btn-outline-success btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="crearNuevoRegistroProfesorColegio()">
                                          <i class="ace-icon fa fa-plus bigger-130"></i>
                                      </a>
                                  </h5>
                                  </div>
                          
                                  <div class="card-body p-0 bg-white">
                                      <table class="table table-striped table-bordered table-hover">
                                          <thead>
                                              <tr>
                                                  <th>
                                                      <label>Nº</label>
                                                  </th>
                                                  <th>
                                                      <label>Gestión</label>
                                                  </th>
                                                  <th>
                                                      <label>Curso</label>
                                                  </th>
                                                  <th>
                                                      <label>Materia</label>
                                                  </th>
                                                  <th>
                                                      <label>Acciones</label>
                                                  </th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <ng-container *ngFor="let registro_colegio_profesor of empleado.registros_colegio_profesor;let i=index;">
                                                  <tr *ngIf="!registro_colegio_profesor.eliminado">
                                                      <td>
                                                          {{(i+1)}}
                                                      </td>
                                                      <td>
                                                          {{registro_colegio_profesor.gestion.nombre}}
                                                      </td>
                                                      <td>
                                                          {{registro_colegio_profesor.curso.nombre}}
                                                      </td>
                                                      <td>
                                                          {{registro_colegio_profesor.materia.nombre}}
                                                      </td>
                                                      <td>
                                                          <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionRegistroProfesorColegio(registro_colegio_profesor)">
                                                              <i class="ace-icon fa fa-trash-alt bigger-130"></i>
                                                          </a>
                                                      </td>
                                                  </tr>
                                              </ng-container>
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
  
                <div id="step-3" *ngIf="usuario.empresa.usar_cuentas_empleados">
                  <div class="  form-row">
                    <div [ngClass]="{'text-danger-m1':(!empleado.persona.usuario.nombre_usuario)}" class="col-4 col-form-label text-sm-right">
                        Usuario
                    </div>
                    <div class="col-8">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <input (blur)="validarUsuario(empleado.persona.usuario)" required type="email" id="nombre_usuario" name="nombre_usuario" [(ngModel)]="empleado.persona.usuario.nombre_usuario" placeholder="Nombre de usuario" [ngClass]="{'brc-danger-m1':(!empleado.persona.usuario.nombre_usuario)}" class="form-control form-control-lg col-12 pr-45 pl-25"  />
                            <i *ngIf="!empleado.persona.usuario.nombre_usuario" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                        </div>
                        <div *ngIf="!empleado.persona.usuario.nombre_usuario" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                            <div class="arrow brc-danger-m1 mt-0"></div>
                            <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                        </div>
                        <span *ngIf="!empleado.persona.usuario.nombre_usuario" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                    </div>
    
                    <div [ngClass]="{'text-danger-m1':(!empleado.persona.usuario.clave)}" class="col-4 col-form-label text-sm-right">
                        Clave
                    </div>
                    <div class="col-8">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <input required type="password" id="clave" name="clave" [(ngModel)]="empleado.persona.usuario.clave" placeholder="Clave" [ngClass]="{'brc-danger-m1':(!empleado.persona.usuario.clave)}" class="form-control form-control-lg col-12 pr-45 pl-25" />
                            <i *ngIf="!empleado.persona.usuario.clave" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                        </div>
                        <div *ngIf="!empleado.persona.usuario.clave" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                            <div class="arrow brc-danger-m1 mt-0"></div>
                            <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                        </div>
                        <span *ngIf="!empleado.persona.usuario.clave" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                    </div>
    
                    <div [ngClass]="{'text-danger-m1':(!empleado.persona.usuario.clave)}" class="col-4 col-form-label text-sm-right">
                        Repeticion Clave
                    </div>
                    <div class="col-8">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <input value="{{empleado.persona.usuario.clave}}" (blur)="verificarClaveRepeticion(empleado.persona.usuario)" required type="password" id="repeticion_clave" name="repeticion_clave" [(ngModel)]="empleado.persona.usuario.repeticion_clave" placeholder="Repeticion Clave" [ngClass]="{'brc-danger-m1':(!empleado.persona.usuario.repeticion_clave)}" class="form-control form-control-lg col-12 pr-45 pl-25" />
                            <i *ngIf="!empleado.persona.usuario.repeticion_clave" class="fas fa-times-circle text-danger-m2 text-125 ml-n425 pos-rel"></i>
                        </div>
                        <div *ngIf="!empleado.persona.usuario.repeticion_clave" class="d-none d-sm-inline-block form-text ml-sm-2 popover brc-danger-m3 border-2 bs-popover-right">
                            <div class="arrow brc-danger-m1 mt-0"></div>
                            <div class="popover-body text-dark-tp4 text-110">{{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}}</div>
                        </div>
                        <span *ngIf="!empleado.persona.usuario.repeticion_clave" class="d-inline-block d-sm-none form-text text-danger-m1 text-600 text-95 ml-sm-2"> {{global_variable.MENSAJES_VALIDACION.DATO_REQUERIDO}} </span>
                    </div>
    
                    <ng-container *ngIf="!empleado.persona.usuario.id">
                        <div class="col-4 col-form-label text-sm-right">
                            Rol
                        </div>
                        <div class="col-8">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <select (change)="buscarAplicacionesRol(empleado.persona.usuario.rol)" [compareWith]="compararObjectos" class="form-control" required name="rol" [(ngModel)]="empleado.persona.usuario.rol">
                                    <option *ngIf="!empleado.persona.usuario.id" [ngValue]="null" selected>Ninguno</option>
                                    <option [ngValue]="rol" *ngFor="let rol of roles_registro">{{rol.nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="usuario.empresa.usar_sucursales && !empleado.persona.usuario.id">
                        <div class="col-4 col-form-label text-sm-right">
                            Sucursales
                        </div>
                        <div class="col-8">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <ngx-bootstrap-multiselect *ngIf="empleado.persona.usuario.sucursales" [options]="sucursales" [(ngModel)]="empleado.persona.usuario.sucursales" (ngModelChange)="seleccionarSucursal($event)"></ngx-bootstrap-multiselect>
                            </div>
                        </div>
                    </ng-container>
                  </div>
                  <div class="  form-row">
                    <div class="col-sm-2 col-form-label text-sm-right">¿Activo?</div>
                    <div class="col-sm-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <label>
                                <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="empleado.persona.usuario.activo"/>
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-2 col-form-label text-sm-right">¿Cierre de Sesión Automático?</div>
                    <div class="col-sm-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <label>
                                <input disabled name="switch-field-2" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="empleado.persona.usuario.cierre_sesion_automatico"/>
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-2 col-form-label text-sm-right">¿Multidispositivo?</div>
                    <div class="col-sm-2">
                        <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                            <label>
                                <input disabled name="switch-field-3" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="empleado.persona.usuario.multidispositivo"/>
                            </label>
                        </div>
                    </div>
                    <ng-container *ngIf="!empleado.persona.usuario.id">
                        <div class="col-sm-2 col-form-label text-sm-right">¿Enviar Correo?</div>
                        <div class="col-sm-2">
                            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                                <label>
                                    <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="empleado.persona.usuario.enviar_correo"/>
                                </label>
                            </div>
                        </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
  
          </div>
        </div> <!-- .card -->
  
      </div>
    </div>
</ng-template>

<ng-template #registro_profesor_instituto_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Docente</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="agregarRegistroProfesorInstituto()" class="btn btn-primary"  >
            <i class="fa fa-plus text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.close('')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-4">
            <label>Carrera</label>
        </div>
        <div class="col-sm-12 col-md-8">
            <select [compareWith]="compararObjectos" name="carrera" class="form-control" [(ngModel)]="registro_instituto_profesor.carrera">
                <option [ngValue]="carrera" *ngFor="let carrera of carreras">{{carrera.nombre}}</option>
            </select>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-4">
            <label>Gestión</label>
        </div>
        <div class="col-sm-12 col-md-8">
            <select [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="registro_instituto_profesor.gestion">
                <option [ngValue]="gestion" *ngFor="let gestion of gestiones">{{gestion.nombre}}</option>
            </select>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-4">
            <label>Semestre</label>
        </div>
        <div class="col-sm-12 col-md-8">
            <select [compareWith]="compararObjectos" name="semestre" class="form-control" [(ngModel)]="registro_instituto_profesor.semestre">
                <option [ngValue]="semestre" *ngFor="let semestre of semestres">{{semestre.nombre}}</option>
            </select>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #registro_profesor_colegio_modal let-modal>
    <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Registro Docente</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="agregarRegistroProfesorColegio()" class="btn btn-primary"  >
              <i class="fa fa-plus text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.close('')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
      <div class="row">
          <div class="col-sm-12 col-md-4">
              <label>Gestión</label>
          </div>
          <div class="col-sm-12 col-md-8">
              <select (change)="establecerGestionColegio()" [compareWith]="compararObjectos" name="gestion" class="form-control" [(ngModel)]="registro_colegio_profesor.gestion">
                  <option [ngValue]="gestion" *ngFor="let gestion of gestiones | filterBy:{habilitado:true}">{{gestion.nombre}}</option>
              </select>
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-4">
              <label>Nivel</label>
          </div>
          <div class="col-sm-12 col-md-8">
              <select [compareWith]="compararObjectos" (change)="cambiarNivel(registro_colegio_profesor.nivel_escolar.id)" name="nivel" class="form-control" [(ngModel)]="registro_colegio_profesor.nivel_escolar">
                  <option [ngValue]="nivel" *ngFor="let nivel of filter.niveles">{{nivel.nombre}}</option>
              </select>
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-4">
              <label>Curso</label>
          </div>
          <div class="col-sm-12 col-md-8">
              <select [compareWith]="compararObjectos" name="curso" class="form-control" [(ngModel)]="registro_colegio_profesor.curso">
                  <option [ngValue]="curso" *ngFor="let curso of filter.cursos">{{curso.nombre}}</option>
              </select>
          </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-sm-12 col-md-4">
            <label>Materia</label>
        </div>
        <div class="col-sm-12 col-md-8">
            <select [compareWith]="compararObjectos" name="materia" class="form-control" [(ngModel)]="registro_colegio_profesor.materia">
                <option [ngValue]="materia" *ngFor="let materia of filter.materias">{{materia.nombre}}</option>
            </select>
        </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-4">
              <label>Horas</label>
          </div>
          <div class="col-sm-12 col-md-8">
              <input type="number" name="horas" [(ngModel)]="registro_colegio_profesor.horas" placeholder="Horas" class="form-control"/>
          </div>
      </div>
      <hr>
      <div class="row">
          <div class="col-sm-12 col-md-4">
              <label>Observación</label>
          </div>
          <div class="col-sm-12 col-md-8">
              <input type="text" name="observacion" [(ngModel)]="registro_colegio_profesor.observacion" placeholder="Observación" class="form-control"/>
          </div>
      </div>
    </div>
  </ng-template>

<ng-template #lista_turnos_modal let-modal>
    <div class="modal-header card-header">
      <div class="col-md-8 col-xs-8">
          <span class="text-blue text-125">Lista de turnos</span>
      </div>
      <div class="col-md-6 col-xs-6">
          <button (click)="crearNuevoTurno()" class="btn btn-primary"  >
              <i class="fa fa-plus text-110 mr-1"></i>
          </button>&nbsp;
          <button class="btn btn-danger" type="button" (click)="modal.close('')">
              <i class="fa fa-times red2 text-110 mr-1"></i>
          </button>
      </div>
  </div>
  <div class="modal-body card-body">
        <table class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                    <th>Nº</th>
                    <th>Nombre</th>
                    <th>Hora Inicial</th>
                    <th>Hora Final</th>
                    <th>Numero Orden</th>
                    <th>Descripcion</th>
                    <th style="text-align: center; width: 140px; " data-field="tools">
                        <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                        <div class="fht-cell"></div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let turno of turnos; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{turno.nombre}}</td>
                    <td>{{turno.hora_inicio+":"+turno.minuto_inicio}}</td>
                    <td>{{turno.hora_fin+":"+turno.minuto_fin}}</td>
                    <td>{{turno.numero_orden}}</td>
                    <td>{{turno.descripcion}}</td>
                    <td style="text-align: center; width: 140px; ">
                        <div class="action-buttons">        
                          <a *ngIf="aplicacion.puede_modificar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="modificarTurno(turno)">          
                            <i class="fa fa-pencil-alt text-105"></i>        
                          </a>       
                          <a *ngIf="aplicacion.puede_eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionTurno(turno)">          
                            <i class="fa fa-trash-alt text-105"></i>        
                          </a>      
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>  
    </div>
  </ng-template>

<ng-template #registro_turno_modal let-modal>
<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Turno</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button (click)="guardarTurno()" class="btn btn-primary"  >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="modal.close('')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-sm-12 col-md-2">
            <label>Nombre</label>
        </div>
        <div class="col-sm-12 col-md-4">
            <input required type="text" name="nombre" [(ngModel)]="turno.nombre" placeholder="Nombre" class="form-control"/>
        </div>
        <div class="col-sm-12 col-md-3">
            <label>Número Orden</label>
        </div>
        <div class="col-sm-12 col-md-3">
            <input required type="number" name="numero_orden" [(ngModel)]="turno.numero_orden" placeholder="Número Orden" class="form-control"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-4 col-md-4">
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <label class="text-success-d1">Hora Inicio</label>
                </div>
                <div class="col-sm-6 col-md-6">
                    <label class="text-success-d1">Minuto Inicio</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <input name="hora_inicio" required type="number"[(ngModel)]="turno.hora_inicio" placeholder="Hora Inicio" class="form-control" />
                </div>
                <div class="col-sm-6 col-md-6">
                    <input name="minuto_inicio" required type="number"[(ngModel)]="turno.minuto_inicio" placeholder="Minuto Inicio" class="form-control" />
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-6">
            <div class="row">
                <div class="col-sm-3 col-md-3">
                    <label class="text-danger-d1">Tickeo Hora Inicio</label>
                </div>
                <div class="col-sm-3 col-md-3">
                    <label class="text-danger-d1">Tickeo Minuto Inicio</label>
                </div>
                <div class="col-sm-3 col-md-3">
                    <label class="text-danger-d1">Tickeo Hora Fin</label>
                </div>
                <div class="col-sm-3 col-md-3">
                    <label class="text-danger-d1">Tickeo Minuto Fin</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 col-md-3">
                    <input name="tickeo_inicio_hora_inicio" required type="number"[(ngModel)]="turno.tickeo_inicio_hora_inicio" placeholder="Hora Inicio" class="form-control" />
                </div>
                <div class="col-sm-3 col-md-3">
                    <input name="tickeo_inicio_minuto_inicio" required type="number"[(ngModel)]="turno.tickeo_inicio_minuto_inicio" placeholder="Minuto Inicio" class="form-control" />
                </div>
                <div class="col-sm-3 col-md-3">
                    <input name="tickeo_inicio_hora_fin" required type="number"[(ngModel)]="turno.tickeo_inicio_hora_fin" placeholder="Hora Inicio" class="form-control" />
                </div>
                <div class="col-sm-3 col-md-3">
                    <input name="tickeo_inicio_minuto_fin" required type="number"[(ngModel)]="turno.tickeo_inicio_minuto_fin" placeholder="Minuto Inicio" class="form-control" />
                </div>
            </div>
        </div>
        <div class="col-sm-2 col-md-2">
            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <label class="text-primary-d1">Minutos Tolerancia Entrada</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <input name="minutos_tolerancia_entrada" required type="number"[(ngModel)]="turno.minutos_tolerancia_entrada" placeholder="Min." class="form-control" />
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-4 col-md-4">
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <label class="text-success-d1">Hora Fin</label>
                </div>
                <div class="col-sm-6 col-md-6">
                    <label class="text-success-d1">Minuto Fin</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6">
                    <input name="hora_fin" required type="number"[(ngModel)]="turno.hora_fin" placeholder="Hora Fin" class="form-control" />
                </div>
                <div class="col-sm-6 col-md-6">
                    <input name="minuto_fin" required type="number"[(ngModel)]="turno.minuto_fin" placeholder="Minuto Fin" class="form-control" />
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-6">
            <div class="row">
                <div class="col-sm-3 col-md-3">
                    <label class="text-danger-d1">Tickeo Hora Inicio</label>
                </div>
                <div class="col-sm-3 col-md-3">
                    <label class="text-danger-d1">Tickeo Minuto Inicio</label>
                </div>
                <div class="col-sm-3 col-md-3">
                    <label class="text-danger-d1">Tickeo Hora Fin</label>
                </div>
                <div class="col-sm-3 col-md-3">
                    <label class="text-danger-d1">Tickeo Minuto Fin</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 col-md-3">
                    <input name="tickeo_fin_hora_inicio" required type="number"[(ngModel)]="turno.tickeo_fin_hora_inicio" placeholder="Hora" class="form-control" />
                </div>
                <div class="col-sm-3 col-md-3">
                    <input name="tickeo_fin_minuto_inicio" required type="number"[(ngModel)]="turno.tickeo_fin_minuto_inicio" placeholder="Minuto" class="form-control" />
                </div>
                <div class="col-sm-3 col-md-3">
                    <input name="tickeo_fin_hora_fin" required type="number"[(ngModel)]="turno.tickeo_fin_hora_fin" placeholder="Hora" class="form-control" />
                </div>
                <div class="col-sm-3 col-md-3">
                    <input name="tickeo_fin_minuto_fin" required type="number"[(ngModel)]="turno.tickeo_fin_minuto_fin" placeholder="Minuto" class="form-control" />
                </div>
            </div>
        </div>
        <div class="col-sm-2 col-md-2">
            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <label class="text-primary-d1">Minutos Tolerancia Salida</label>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12">
                    <input name="minutos_tolerancia_salida" required type="number"[(ngModel)]="turno.minutos_tolerancia_salida" placeholder="Min." class="form-control" />
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Descripción</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <input name="descripcion" type="text" [(ngModel)]="turno.descripcion" placeholder="Descripción" class="form-control"/>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-3">
            <label>Días</label>
        </div>
        <div class="col-sm-12 col-md-9">
            <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                <ngx-bootstrap-multiselect *ngIf="dias" [options]="dias" [(ngModel)]="turno.dias"></ngx-bootstrap-multiselect>
            </div>
        </div>
    </div>
</div>
</ng-template>

<ng-template #asignacion_turno_empleado_modal let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Asignación de turnos: {{empleado.persona.nombre_completo}}</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button (click)="guardarTurnosEmpleado()" class="btn btn-primary"  >
                <i class="fa fa-save text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.close('')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-sm-12 col-md-2">
                <label>Turnos Disponibles</label>
            </div>
            <div class="col-sm-12 col-md-4">
                <select [compareWith]="compararObjectos" class="form-control" required name="turno" [(ngModel)]="empleado_turno_dato.turno">
                    <option [ngValue]="turno" *ngFor="let turno of turnos">{{turno.nombre}}</option>
                </select>
            </div>
            <div class="col-sm-12 col-md-3">
                <label>Asignar</label>
            </div>
            <div class="col-sm-12 col-md-3">
                <button [disabled]="!empleado_turno_dato.turno" (click)="agregarTurnoEmpleado(empleado_turno_dato)" class="btn btn-success"  >
                    <i class="fas fa-arrow-down text-110 mr-1"></i>
                </button>
            </div>
        </div>
        <hr>
        <div class="row">
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th class="center">N°</th>
                        <th class="center">Turno</th>
                        <th class="center">Periodo</th>
                        <th>Acciones</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let empleado_turno of empleado.turnos | orderBy : 'turno.numero_orden'; let i=index;">
                        <td class="center">
                            {{i+1}}
                        </td>
                        <td class="center">
                            {{empleado_turno.turno.nombre}}
                        </td>
                        <td class="center">
                            {{empleado_turno.turno.hora_inicio}}:{{empleado_turno.turno.minuto_inicio}} - {{empleado_turno.turno.hora_fin}}:{{empleado_turno.turno.minuto_fin}}
                        </td>
                        <td>
                            <div class="hidden-sm hidden-xs action-buttons">
                                 <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionEmpleadoTurno(empleado_turno)">          
                                    <i class="fa fa-trash-alt text-105"></i>        
                                </a>  
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>

<ng-template #consulta_asistencia_modal let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Asistencia de Empleados</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button class="btn btn-danger" type="button" (click)="modal.close('')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-md-3 col-sm-12">
                <div class="input-group">
                    <label for="feini">Desde </label>&nbsp;
                    <input required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="filtro_asistencia.fecha_inicio_texto" ngbDatepicker #fi="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                    </div>
                </div>  
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="input-group">
                    <label for="fefin">Hasta </label>&nbsp;
                    <input required id="fefin" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                            name="dp" [(ngModel)]="filtro_asistencia.fecha_fin_texto" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>  
             </div>
             <div class="col-md-4 col-sm-12">
                <div class="input-group">
                    <label for="fefin">Empleado </label>&nbsp;
                    <select [compareWith]="compararObjectos" class="form-control" required name="empleado" [(ngModel)]="filtro_asistencia.empleado">
                        <option [ngValue]="empleado" *ngFor="let empleado of filtro_asistencia.empleados_asistencia">{{empleado.persona.nombre_completo}}</option>
                    </select>
                </div>  
             </div>
            <div class="col-md-2 col-xs-3 col-sm-3">
                <button (click)="buscarAsistencias()" class="btn btn-outline-default btn-smd bgc-white btn-h-light-primary btn-a-outline-primary py-1" type="button" name="fullscreen" aria-label="Fullscreen" title="Buscar">
                    <i class="fa fa fa-search"></i>
                </button>
            </div>
        </div>
        <hr>
        <div class="table-fixed-header">
            <div tabindex="0" class="col-md-12 focus-data">
                <table id="tabla-asistencia" *ngIf="filtro_asistencia.fechas_visualizacion.length>0" class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th [attr.colspan]="filtro_asistencia.fechas_visualizacion.length" align="center">
                                Días
                                <span class='text-danger'><i class='fas fa-sign-in-alt red2 text-110 mr-1'></i></span>:Entrada retrasada  <span class='text-warning'><i class='fas fa-sign-in-alt red2 text-110 mr-1'></i></span>:Entrada retrasada con tolerancia <span class='text-warning'><i class='fas fa-sign-out-alt red2 text-110 mr-1'></i></span>:Salida anticipada <span class='text-primary'><i class='fas fa-sign-out-alt red2 text-110 mr-1'></i></span>:Salida anticipada con tolerancia
                            </th>
                        </tr>
                        <tr>
                            <th>Nº</th>
                            <th>Empleado</th>
                            <th>Resumen</th>
                            <th *ngFor="let fecha_visualizacion of filtro_asistencia.fechas_visualizacion">{{fecha_visualizacion | date:'dd/MM/yyyy'}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let empleado_asistencia of filtro_asistencia.empleados_asistencias; let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{empleado_asistencia.persona.nombre_completo}}</td>
                            <td><div [innerHtml]="obtenerResumenAsistencia(empleado_asistencia.asistencias)"></div></td>
                            <td *ngFor="let fecha_visualizacion of filtro_asistencia.fechas_visualizacion">
                                <table *ngIf="obtenerDatosAsistencia(empleado_asistencia.asistencias,fecha_visualizacion).length>0" class="table table-striped table-bordered table-hover">
                                    <tr>
                                        <th>Turno</th>
                                        <th>Hora</th>
                                    </tr>
                                    <tr *ngFor="let asistencia of obtenerDatosAsistencia(empleado_asistencia.asistencias,fecha_visualizacion)">
                                        <td>{{asistencia.turno.nombre}}</td>
                                        <td>
                                            <span class="{{asistencia.color_tiempo}}">{{asistencia.tiempo_registro | date:"HH:mm - dd/MM/yyyy"}}</span>
                                            <span class="text-primary" *ngIf="asistencia.entrada"><i class="fas fa-sign-in-alt red2 text-110 mr-1"></i></span>
                                            <span class="text-warning" *ngIf="!asistencia.entrada"><i class="fas fa-sign-out-alt red2 text-110 mr-1"></i></span>
                                        </td>
                                    </tr>
                                </table>
                                <!--<span > 
                                    <a class="red" href="javascript:void(0)" e-disabled="!aplicacion.puede_eliminar" ng-click="abrirPopupConfirmacionEliminacion(eliminarAsistencia,asistencia)">
                                        <i class="ace-icon fa fa-trash-o bigger-130"></i>
                                    </a>
                                </span>-->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modal_empleado_almacen let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Asignacion Empleado: {{empleado.persona.nombre_completo}}</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button class="btn btn-primary" type="button" (click)="crearMovimientoInventarioInicial(empleado)">
                <i class="fas fa-boxes red2 text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.close('')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="table-fixed-header">
            <div tabindex="0" class="col-md-12">
                <table id="tabla-inventarios" class="table table-striped table-bordered table-hover">
                    <thead>
                        <tr>
                            <th class="center">
                                Nº
                            </th>
                            <th class="center">
                                <a href="javascript:void(0)">Código
                                    <span id="codigo" class="sort fa fa-sort icon-only"></span>
                                </a>
                            </th>
                            <th><a href="javascript:void(0)" >Producto <span id="nombre" class="sort fa fa-sort icon-only"></span></a></th>
                            <th><a href="javascript:void(0)" >Unidad de Medida <span id="unidad_medida" class="sort fa fa-sort icon-only"></span></a></th>
                            <th><a href="javascript:void(0)" >Descripción <span id="descripcion" class="sort fa fa-sort icon-only"></span></a></th>
                            <th><a href="javascript:void(0)" >Cantidad Total <span id="cantidad" class="sort fa fa-sort icon-only"></span></a></th>
                        </tr>
                    </thead>
                
                    <tbody>
                        <tr *ngFor="let producto of productos_empleado; let i=index;">
                            <td class="center">
                                {{i+1}}
                            </td>
                            <td>{{producto.codigo}}</td>
                            <td >{{producto.nombre}}</td>
                            <td >{{producto.unidad_medida}}</td>
                            <td >{{producto.descripcion}}</td>
                            <td>
                                <div class="c100 p{{producto.porcentaje}} small {{producto.color}}">
                                    <span style="color:black !important;">{{producto.cantidad}}</span>
                                    <div class="slice">
                                        <div class="bar"></div>
                                        <div class="fill"></div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modal_pago_empleado let-modal>
    <div class="modal-header card-header">
        <div class="col-md-8 col-xs-8">
            <span class="text-blue text-125">Calculo Salario Empleado : {{empleado.persona.nombre_completo}}</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button *ngIf="detalles_pedidos.length>0 && datos_trabajo_empleado.total_pago_empleado>0" class="btn btn-success" type="button" (click)="generarEgreso()">
                <i class="fas fa-money-bill-alt red2 text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.close('')">
                <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-md-6">
                    <h3 class="header smaller lighter blue">Asignado</h3>
                <div class="row">
                    <div class="col-md-6" *ngFor="let producto of productos_empleado">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        Nº
                                    </th>
                                    <th>
                                        {{producto.nombre}}
                                    </th>
                                </tr>
                            </thead>
                        
                            <tbody>
                                <tr *ngFor="let inventario of producto.inventarios; let i=index;">
                                    <td class="center">
                                        {{i+1}}
                                    </td>
                                    <td>{{inventario.cantidad}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                    <h3 class="header smaller lighter blue">Trabajado</h3>
                <table class="table table-striped table-bordered table-hover" *ngIf="detalles_pedidos.length>0">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                Servicio
                            </th>
                            <th>
                                Cantidad
                            </th>
                        </tr>
                    </thead>
                
                    <tbody>
                        <tr *ngFor="let detalle_pedido of detalles_pedidos; let i=index;">
                            <td class="center">
                                {{i+1}}
                            </td>
                            <td>{{detalle_pedido.descripcion}}</td>
                            <td>{{detalle_pedido.cantidad}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6" *ngFor="let producto of productos_empleado">
                        Total : {{calcularTotalInventario(producto.inventarios)}}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                Total : {{calcularTotalGramos()}}
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <label>Total Oro trabajado : </label>
            </div>
            <div class="col-sm-12 col-md-6">
                <p class="text-primary">{{datos_trabajo_empleado.total_oro_trabajado}}</p>
            </div>
            <div class="col-sm-12 col-md-6">
                <label>Total Oro Joyero : </label>
            </div>
            <div class="col-sm-12 col-md-6">
                <p class="text-primary">{{datos_trabajo_empleado.total_oro_joyero}}</p>
            </div>
            <div class="col-sm-12 col-md-6">
                <label>Sueldo : </label>
            </div>
            <div class="col-sm-12 col-md-6">
                <p class="text-primary">{{datos_trabajo_empleado.total_pago_empleado}}</p>
            </div>
        </div>
    </div>
</ng-template>